<template>
  <div class="team-container">
    <aDealHeader></aDealHeader>

    <section class="team-description">
      <img alt="image" src="/hero-divider-1500w.png" class="team-divider-image" />
    </section>
    <section class="team-description1">
      <img alt="pastedImage" src="/external/pastedimage-n4k7-2400h.png" class="team-pasted-image" />
    </section>
    <section class="team-ledership">
      <p class="team-description2">
        <span>Jacob Lee</span>
        <br />
        <span>Team Lead, Product Design</span>
        <br />
        <br />
        <span>
          Jacob Lee is an experienced developer in the blockchain industry since
          2014, co-founder of DTCO, a pioneering blockchain technology firm. With
          a robust background in deploying blockchain solutions in various
          sectors, Jacob has led multiple groundbreaking projects:
        </span>
        <br />
        <br />
        <span>
          IPSeeds Initiative (2016): As the project lead, Jacob spearheaded the
          launch of IPSeeds in collaboration with the Taiwan Research-based
          Biopharmaceutical Manufacturers Association (TRPMA). This platform, a
          Biopharma intellectual property exchange, utilized hybrid Distributed
          Ledger Technology (DLT) integrating Openchain with the Bitcoin network.
          This initiative marked one of the first significant uses of blockchain
          in the biopharmaceutical sector.
        </span>
        <br />
        <br />
        <span>
          phrOS Project (2017): Jacob led the development of phrOS, a Personal
          Health Records Operating System, in partnership with Taipei Medical
          University Hospital. This project was built on Ethereum Enterprise
          Blockchain technology and incorporated IPFS SWAM for enhanced data
          security and interoperability, setting a new standard in healthcare data
          management.
        </span>
        <br />
        <br />
        <span>
          Itera Project (2019): Jacob designed a hardware-based content mining
          machine intended to support a decentralized Content Delivery Network
          (CDN). This project, developed in partnership with GIGABYTE, produced a
          prototype that combined Ethereum Layer 1 with IPFS SWAM. Despite its
          innovative approach, the Itera project was suspended in 2020 due to a
          lack of venture capital funding.
        </span>
        <br />
        <br />
        <span>
          AXON Evolution Collaboration (2021-2022): During the COVID-19 pandemic,
          Jacob led the development team at AXON Evolution to create healthcare
          applications as part of the Dublin Research Project in Ireland. These
          applications were successfully deployed in clinical at Ireland, aiding
          in the management and mitigation of the pandemic.
        </span>
        <br />
        <br />
        <span>
          Tao Passport &amp; Tao Coin (2018-2019): Demonstrating his expertise in
          UI/UX and blockchain application design, Jacob led the development of
          the Tao Passport and Tao Coin. These projects introduced
          blockchain-based self-sovereign identity passports and community
          currencies, co-developed and successfully launched with indigenous
          communities on Orchid Island, Taiwan. These initiatives showcased the
          practical implementation of blockchain technology in fostering local
          economic development and cultural preservation.
        </span>
        <br />
        <br />
        <span>
          Jacob also serves as the Director of Design at TRPMA starting in 2021,
          which is ISO 27001/27701 PIMS certified. His leadership has been
          instrumental in developing numerous government-backed projects,
          leveraging his expertise in design and technology integration.
        </span>
        <br />
        <br />
        <span>LinkedIn Profiles</span>
        <br />
        <span>https://www.linkedin.com/in/jacob-lee-aa435916/</span>
        <br />
      </p>
    </section>
    <section class="team-description3">
      <img alt="pastedImage" src="/external/pastedimage-tcdr-800w.png" />
    </section>
    <section class="team-ledership1">
      <p class="team-description4">
        <span>Brady Liu</span>
        <br />
        <span>Tech Lead, Blockchain Engineer</span>
        <br />
        <br />
        <span>
          Brady Liu, the co-founder and Chief Technology Officer of Digital
          Treasury Corporation (DTCO), stands at the forefront of blockchain
          technology and software development. With a track record of leadership
          across various landmark projects, with fullstack engineering development
          skills, combined with a deep expertise in blockchain technology.
        </span>
        <br />
        <br />
        <span>Front-End Expertise:</span>
        <br />
        <span>
          HTML, CSS, and JavaScript, Vue.js framework, able to develop dynamic
          user interfaces that are both aesthetically pleasing and functionally
          rich.
        </span>
        <br />
        <br />
        <span>Back-End Development:</span>
        <br />
        <span>
          Java and related frameworks to build secure and scalable RESTful APIs.
          AWS Cloud based high-performance database integration, architectures
          that support complex applications.
        </span>
        <br />
        <br />
        <span>Blockchain Engineering:</span>
        <br />
        <span>
          Open Asset Protocol(Bitcoin network) and EVM blockchain engineer,
          Solidity programming language.
        </span>
        <br />
        <br />
        <span>DApp Frontend Integration:</span>
        <br />
        <span>
          Web3.js, ethers.js, and development frameworks like Truffle. Create
          seamless interactions between user interfaces and blockchain
          functionalities, ensuring a coherent and user-friendly experience.
        </span>
        <br />
        <br />
        <span>LinkedIn Profiles</span>
        <br />
        <span>https://www.linkedin.com/in/brady-liu-734a27106/</span>
        <br />
      </p>
    </section>

    <aDealFooter></aDealFooter>

  </div>
</template>

<script>
import aDealHeader from './adeal-header'
import aDealFooter from './adeal-footer'

export default {
  components: {
    aDealHeader,
    aDealFooter,
  },
  name: 'Team',
  metaInfo: {
    title: 'team - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'team - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
}
</script>

<style scoped>
.team-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}

.team-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.team-logo {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.team-desktop-menu {
  display: flex;
}

.team-nav {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.team-button {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.team-button1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.team-button2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.team-button3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.team-button4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.team-btn-group {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.team-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.team-image {
  width: 100px;
  object-fit: cover;
}

.team-icon {
  width: 24px;
  height: 24px;
}

.team-image1 {
  width: 100px;
  object-fit: cover;
}

.team-icon02 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}

.team-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}

.team-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.team-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.team-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.team-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.team-logo1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.team-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.team-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.team-text {
  margin-bottom: var(--dl-space-space-unit);
}

.team-text01 {
  margin-bottom: var(--dl-space-space-unit);
}

.team-text02 {
  margin-bottom: var(--dl-space-space-unit);
}

.team-text03 {
  margin-bottom: var(--dl-space-space-unit);
}

.team-text04 {
  margin-bottom: var(--dl-space-space-unit);
}

.team-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}

.team-login {
  margin-right: var(--dl-space-space-twounits);
}

.team-icon-group {
  display: flex;
}

.team-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.team-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.team-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.team-description {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.team-divider-image {
  width: 100%;
  object-fit: cover;
}

.team-description1 {
  gap: 120px;
  width: 438px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.team-pasted-image {
  width: auto;
  height: auto;
  align-self: center;
}

.team-ledership {
  gap: var(--dl-space-space-threeunits);
  width: 1048px;
  height: 2006px;
  display: flex;
  max-width: 1440px;
  align-self: center;
  padding-left: 343px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}

.team-description2 {
  color: var(--dl-color-gray-white);
  width: 578px;
  font-size: 18px;
  max-width: 500px;
  line-height: 27px;
}

.team-description3 {
  gap: 120px;
  width: 438px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.team-ledership1 {
  gap: var(--dl-space-space-threeunits);
  width: 1048px;
  height: 1189px;
  display: flex;
  max-width: 1440px;
  align-self: center;
  padding-left: 343px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}

.team-description4 {
  color: var(--dl-color-gray-white);
  width: 578px;
  font-size: 18px;
  max-width: 500px;
  line-height: 27px;
}

.team-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}

.team-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.team-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}

.team-heading {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.team-logo2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.team-caption {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.team-socials1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.team-twitter1 {
  text-decoration: none;
}

.team-image2 {
  width: 100px;
  object-fit: cover;
}

.team-discord1 {
  text-decoration: none;
}

.team-image3 {
  width: 100px;
  object-fit: cover;
}

.team-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.team-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.team-heading1 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.team-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.team-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.team-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.team-heading2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.team-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.team-link1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.team-link2 {
  color: rgb(255, 255, 255);
}

.team-link3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.team-link4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.team-socials2 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.team-twitter2 {
  text-decoration: none;
}

.team-image4 {
  width: 100px;
  object-fit: cover;
}

.team-discord2 {
  text-decoration: none;
}

.team-image5 {
  width: 100px;
  object-fit: cover;
}

.team-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.team-container4 {
  display: contents;
}

@media(max-width: 991px) {
  .team-desktop-menu {
    display: none;
  }

  .team-btn-group {
    display: none;
  }

  .team-burger-menu {
    display: flex;
  }

  .team-button5 {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: row;
  }

  .team-logo1 {
    color: #000000;
  }

  .team-description1 {
    width: 412px;
  }

  .team-pasted-image {
    align-self: center;
  }

  .team-ledership {
    height: 2003px;
    align-items: center;
    flex-direction: column;
  }

  .team-description2 {
    width: 542px;
    height: 1974px;
    padding-top: 0px;
  }

  .team-description3 {
    width: 412px;
  }

  .team-ledership1 {
    height: 2451px;
    align-items: center;
    flex-direction: column;
  }

  .team-description4 {
    height: 1917px;
    padding-top: 0px;
  }
}

@media(max-width: 767px) {
  .team-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .team-description {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }

  .team-description1 {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }

  .team-ledership {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .team-description2 {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
  }

  .team-description3 {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }

  .team-ledership1 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .team-description4 {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
  }

  .team-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .team-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }

  .team-heading {
    gap: var(--dl-space-space-unit);
  }

  .team-logo2 {
    font-size: 20px;
    line-height: 24px;
  }

  .team-caption {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }

  .team-socials1 {
    display: none;
  }

  .team-links {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }

  .team-list {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .team-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .team-socials2 {
    display: flex;
  }
}

@media(max-width: 479px) {
  .team-navbar {
    padding: var(--dl-space-space-unit);
  }

  .team-mobile-menu {
    padding: 16px;
  }

  .team-ledership {
    height: 1346px;
    align-items: center;
  }

  .team-description2 {
    width: 479px;
    height: 1286px;
  }

  .team-ledership1 {
    height: 801px;
    align-items: center;
    margin-bottom: 0px;
  }

  .team-description4 {
    width: 479px;
    height: 787px;
  }
}
</style>
