<template>
<div class="terms-conditions-container">
    <section class="terms-conditions-collection">
      <div class="terms-conditions-content">
        <div class="terms-conditions-heading">
          <h2 class="terms-conditions-header">
            Terms and Conditions of the aDeal network Backers Program
          </h2>
          <p class="terms-conditions-header1">
            <span>Article 1 – Scope of Application</span>
            <br />
            <span><span v-html="rawo3cn"></span></span>
            <br />
            <span>
              These Terms and Conditions (&quot;Terms&quot;) govern the
              distribution of ADV tokens by aDeal DAO (&quot;DAO&quot;) to the
              backers (&quot;Backers&quot;) participating in the Backers Program.
              These Terms set the legal framework and outline the rights and
              obligations of both the DAO and the Backers.
            </span>
            <br />
            <br />
            <span>Article 2 – Definitions</span>
            <br />
            <span><span v-html="rawom9f"></span></span>
            <br />
            <span>
              - &quot;ADV Tokens&quot;: The digital tokens distributed by the DAO
              during the Backers Program.
            </span>
            <br />
            <span>
              - &quot;Backers&quot;: Individuals or entities acquiring ADV Tokens.
            </span>
            <br />
            <span>
              - &quot;Backers Program&quot;: The program conducted by the DAO for
              the distribution of ADV Tokens.
            </span>
            <br />
            <br />
            <span>Article 3 – Participation Eligibility</span>
            <br />
            <span><span v-html="rawoqyp"></span></span>
            <br />
            <span>
              Participation in the Backers Program is open to eligible parties
              except for:
            </span>
            <br />
            <span>
              - Residents and citizens of jurisdictions on the designated
              blacklist, which includes countries under sanctions by the UN, US,
              EU, or identified by FATF as high risk.
            </span>
            <br />
            <span>
              - U.S. citizens or residents, in compliance with U.S. securities
              laws.
            </span>
            <br />
            <br />
            <span>Article 4 – Token Sale Mechanics</span>
            <br />
            <span><span v-html="rawexzo"></span></span>
            <br />
            <span>
              - The Backers Program will proceed in phases, each with a defined
              number of tokens available at set prices.
            </span>
            <br />
            <span>
              - The sale phases include backers program, private sale, market
              makers and public IDO stages, each governed by specific conditions
              detailed herein.
            </span>
            <br />
            <br />
            <span>Article 5 – Purchaser Acknowledgment</span>
            <br />
            <span><span v-html="rawtuiz"></span></span>
            <br />
            <span>
              By acquiring ADV Tokens, Backers acknowledge the risks associated
              with blockchain technology and digital tokens, including potential
              loss of value and lack of liquidity. Backers accept these Terms
              unreservedly and confirm they are legally authorized to participate
              in the Backers Program.
            </span>
            <br />
            <br />
            <span>Article 6 – Use of Proceeds</span>
            <br />
            <span><span v-html="raw5v5j"></span></span>
            <br />
            <span>
              Funds raised through the Backers Program will be utilized for the
              development of the aDeal Network platform, operational costs, and
              future research and development as detailed in the Backers Program
              whitepaper. https://adeal.network/white-paper
            </span>
            <br />
            <br />
            <span>Article 7 – Rights and Obligations of Token Holders</span>
            <br />
            <span><span v-html="rawdaa5"></span></span>
            <br />
            <span>
              Backers receive certain rights with ADV Tokens, including the
              ability to use tokens within the aDeal Network platform. Specific
              rights related to governance, such as voting on certain platform
              decisions, will be granted as outlined in the platform&apos;s
              governance model.
            </span>
            <br />
            <br />
            <span>Article 8 – Compliance with Regulatory Requirements</span>
            <br />
            <br />
            <span>
              The Backers Program will adhere to all applicable regulatory
              requirements, including KYC (Know Your Customer) and AML (Anti-Money
              Laundering) standards. Backers must comply with all such
              requirements and provide all requested documentation.
            </span>
            <br />
            <br />
            <span>Article 9 – Intellectual Property</span>
            <br />
            <span><span v-html="raw3syi"></span></span>
            <br />
            <span>
              The DAO retains all rights to its intellectual property. Backers are
              granted a license to use the aDeal Network platform under the
              conditions set by the DAO, but no ownership of software, technology,
              or trademarks is transferred.
            </span>
            <br />
            <br />
            <span>Article 10 – Modification and Termination</span>
            <br />
            <span><span v-html="rawxe46"></span></span>
            <br />
            <span>
              The DAO reserves the right to modify these Terms or terminate the
              Backers Program at any time due to changes in legislation, market
              conditions, or other factors. Any changes will be communicated to
              Backers through official channels.
            </span>
            <br />
            <br />
            <span>Article 11 – Dispute Resolution</span>
            <br />
            <br />
            <span>
              Any disputes arising under these Terms will be resolved through arbitration in Singapore, according to the rules of the Singapore International Arbitration Centre (SIAC). This arbitration will be conducted in English and the arbitrator’s decision shall be final and binding on all parties.
            </span>
            <br />
            <br />
            <span>Article 12 – Organization Identity</span>
            <br />
            <span><span v-html="rawd413"></span></span>
            <br />
            <span>
              The DAO is a decentralized group of developers currently operating
              without a formal legal structure and is in the process of
              establishing a formal legal entity in Zug, Switzerland. This
              document will be updated accordingly once the legal entity is
              established.
            </span>
            <br />
            <br />
            <span>Article 13 – Final Provisions</span>
            <br />
            <span><span v-html="rawnu18"></span></span>
            <br />
            <span>
              These Terms constitute the entire agreement between the Backers and
              the DAO regarding the Backers Program. They replace all prior
              agreements and understandings regarding the subject matter hereof.
            </span>
            <br />
            <br />
          </p>
        </div>
      </div>
    </section>
    
    <div class="cta1-container thq-section-padding cta1-root-class-name">
      <div class="cta1-max-width thq-section-max-width">
        <div class="cta1-content">
          <div class="cta1-container1"><input type="checkbox" v-model='agreed'/>
            <h3 class="cta1-heading1 thq-heading-2">{{ heading1 }}</h3>
          </div>
          <p class="cta1-content1 thq-body-large">{{ content1 }}
            <br /><span>To purchase ADV tokens, please ensure you have MetaMask installed and set up on your browser.</span>
          </p>
          <div class="cta1-container2">
            <div class="cta1-container3">
              <button class="thq-button-outline cta1-button"><span class="cta1-action2 thq-body-small">{{ action2 }}</span></button>
              <div class="cta1-actions"></div>
            </div>
            <button v-if='agreed' class="thq-button-filled cta1-button1" @click='goAgreed'><span class="cta1-action1 thq-body-small">{{ action1 }}</span></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import AppCta1 from '../components/cta1'

export default {
  name: 'TermsConditions',
  props: {
    action1: {
      type: String,
      default: 'I Agree',
    },
    heading1: {
      type: String,
      default:
        'I have read and agree to the Terms and Conditions of the aDeal network Backers Program',
    },
    content1: {
      type: String,
      default:
        'For questions or more information about these Terms, please contact us at adeal@adeal.network',
    },
    action2: {
      type: String,
      default: 'Cancel',
    },
    rootClassName: String,
  },
  // components: {
  //   AppCta1,
  // },
  data() {
    return {
      rawpc1f: ' ',
      rawvo6h: ' ',
      rawakvy: ' ',
      rawkppj: ' ',
      rawmr2y: ' ',
      rawgwz1: ' ',
      raw9ze0: ' ',
      raw1f3g: ' ',
      raw8ify: ' ',
      rawg7n9: ' ',
      raw13w1: ' ',
      agreed:false,
      metamask_address:'',
    }
  },
  metaInfo: {
    title: 'TermsConditions - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'TermsConditions - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
  methods:{
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    connect() {
      if(this.metamask_address.length > 0) {
        this.checkUser(this.metamask_address);
        return;
      }
      window.connectMetaMask(this.handleAccountsChanged);
    },
    handleAccountsChanged(accounts) {
      console.log('handleAccountsChanged', accounts)
      this.metamask_address = accounts[0];
      this.checkUser(this.metamask_address);
    },
    async checkUser(address) {
      const [error, data]= await getUserInfo(address);
      if(error==0) window.open('/purchase');
      else {
        alert(data);
        if(error==102) window.open('/sign-open-letter');
      }
    },
    goAgreed() {
      if(this.isMobile()) {
        alert("Please use your PC browser to open this page and connect with Metamask");
        return;
      }
      if(!window.matamaskInstalled) {
        alert('MetaMask is not available');
      }
      else this.connect();     
    },
  },
  mounted(){
		
	}
}
</script>

<style scoped>
.terms-conditions-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.terms-conditions-collection {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  /* height: 1563px; */
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.terms-conditions-content {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-conditions-heading {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  max-width: auto;
  align-items: flex-start;
  flex-direction: column;
}
.terms-conditions-header {
  color: rgb(255, 255, 255);
  width: 1039px;
  /* height: 195px; */
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}
.terms-conditions-header1 {
  color: rgb(255, 255, 255);
}
@media(max-width: 991px) {
  .terms-conditions-header1 {
    width: 905px;
  }
}
@media(max-width: 767px) {
  .terms-conditions-collection {
    gap: var(--dl-space-space-fourunits);
    /* height: 1761px; */
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .terms-conditions-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .terms-conditions-heading {
    gap: var(--dl-space-space-unit);
  }
  .terms-conditions-header {
    font-size: 32px;
    letter-spacing: 0;
  }
  .terms-conditions-header1 {
    font-size: 14px;
    line-height: 21px;
  }
}
@media(max-width: 479px) {
  .terms-conditions-collection {
    /* height: 2170px; */
    padding-bottom: 0px;
  }
  .terms-conditions-header {
    width: 452px;
    /* height: 103px; */
  }
  .terms-conditions-header1 {
    width: 452px;
    /* height: 1978px; */
  }
}

.cta1-container {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}
.cta1-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.cta1-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.cta1-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.cta1-heading1 {
  width: 938px;
  height: 111px;
  text-align: center;
}
.cta1-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.cta1-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.cta1-action2 {
  text-align: center;
}
.cta1-actions {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.cta1-action1 {
  text-align: center;
}
.cta1-root-class-name {
  fill: var(--dl-color-gray-black);
  color: #ffffff;
}
@media(max-width: 991px) {
  .cta1-content {
    width: 100%;
  }
  .cta1-checkbox {
    flex: 1;
    height: var(--dl-size-size-medium);
  }
  .cta1-heading1 {
    width: 825px;
  }
}
@media(max-width: 767px) {
  .cta1-container {
    width: 723px;
    height: 337px;
  }
  .cta1-heading1 {
    width: 613px;
    height: 168px;
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .cta1-heading1 {
    width: 436px;
    height: 142px;
    font-size: 24px;
  }
  .cta1-button {
    width: 100%;
  }
  .cta1-actions {
    width: 100%;
    flex-direction: column;
  }
  .cta1-button1 {
    width: 100%;
  }
}
</style>
