<template>
  <div class="roadmap-container">
    <aDealHeader></aDealHeader>

    <section class="roadmap-collection">
      <div class="roadmap-content">
        <div class="roadmap-heading">
          <h2 class="roadmap-header"><span>aDeal network Development Roadmap</span><br /></h2>
          <p class="roadmap-header01"><span>Here you&apos;ll find all the key milestones of aDeal network
              development roadmap</span><br /><br /></p>
        </div>
      </div>
    </section>
    <section class="roadmap-roadmap">
      <div class="roadmap-list">
        <div class="roadmap-step"><span class="roadmap-caption">Milestone 1 - Q4, 2023</span>
          <div class="roadmap-heading01">
            <h2 class="roadmap-header02">Concept Development and Product Design</h2>
            <p class="roadmap-header03"><span>Initiation of the aDeal project by core developers Jacob Lee and
                Brady Liu. </span><span>Conduct thorough research on blockchain technologies and resource
                availability to support the aDeal network development. </span><span>Prepare comprehensive design
                documents outlining the project
                architecture, user experience, and technical
                specifications. </span><span>Engage early with potential users and stakeholders to validate
                the project concept and refine the product design based on
                feedback.</span><br /></p>
          </div>
        </div>
        <div class="roadmap-step01"><span class="roadmap-caption01">Milestone 2 - Q2, 2024</span>
          <div class="roadmap-heading02">
            <h2 class="roadmap-header04">Foundation and Community Engagement</h2>
            <p class="roadmap-header05"><span>Set up the aDeal DAO on Aragon platform with clear governance
                rules and initial objectives. Begin with discussion forums involve
                with the crypto community.</span><br /></p>
          </div>
        </div>
        <div class="roadmap-step02"><span class="roadmap-caption02">Milestone 3 - Q2, 2024</span>
          <div class="roadmap-heading03">
            <h2 class="roadmap-header06">Backers Program</h2><button class="roadmap-button6 button"
              @click="goBackersProgram">Be a Backer</button>
            <p class="roadmap-header07"><span>Initiate a structured backers program designed to build a core
                group of early supporters who share our vision for revolutionizing
                web3 advertising.</span><br /><br /><span>The Backers Program is a cornerstone of the aDeal
                network&apos;s
                early development strategy, designed to build a robust and
                supportive community foundation while securing necessary funding
                for initial project milestones. It offers a balanced opportunity
                for early investors to participate in a potentially revolutionary
                web3-based advertising network, with a structure that aims to
                align the interests of the developers and the backers for mutual
                long-term benefit.</span><br /><br /><span>For more details of Backers Program,
                <span v-html="rawwfwe"></span></span><router-link to="/backers-program" class="roadmap-navlink">please
                reference here</router-link><span>.</span><br /><br /><br /></p>
          </div>
        </div>
        <div class="roadmap-step03"><span class="roadmap-caption03">Milestone 4 - Q3 to Q4, 2024</span>
          <div class="roadmap-heading04">
            <h2 class="roadmap-header08">Development and MVP Launch</h2>
            <p class="roadmap-header09">Development of the MVP focusing on core functionalities like user
              avatar management and ad matching algorithms, business AD backend.
              Launch the MVP for early adopter testing and feedback.</p>
          </div>
        </div>
        <div class="roadmap-step04"><span class="roadmap-caption04">Milestone 5 - Q4 2024</span>
          <div class="roadmap-heading05">
            <h2 class="roadmap-header10">Staking Implementation</h2>
            <p class="roadmap-header11"><span class="roadmap-text28">Implement staking of ADV to mint ADP (AD Points)
                for business ad
                placements with a 30% annual generation rate in first year.
                Prepare for larger scale expansion based on MVP feedback and
                initial staking engagement.</span><br /><br /><span>
                The specific generation rate for subsequent years will be
                determined through a democratic voting process within the aDeal
                DAO</span><br /></p>
          </div>
        </div>
        <div class="roadmap-step05"><span class="roadmap-caption05">Milestone 6 - Q1 2025</span>
          <div class="roadmap-heading06">
            <h2 class="roadmap-header12">Private Offer B</h2>
            <p class="roadmap-header13">Private Offer B
              Conduct Private Offer A with 5% of ADV tokens. Funds raised will be secured in aDeal DAO to support the
              pilot program in one or two countries and support pre-IDO development stages.
            </p>
          </div>
        </div>
        <div class="roadmap-step06"><span class="roadmap-caption06">Milestone 7 - Q1 2025</span>
          <div class="roadmap-heading07">
            <h2 class="roadmap-header14">Official Product Launch and Market Maker Engagement</h2>
            <p class="roadmap-header15">Launch the aDeal network officially with complete functionalities.
              Engage market makers to prepare for IDO and support liquidity on
              DEXs like Uniswap.</p>
          </div>
        </div>
        <div class="roadmap-step07"><span class="roadmap-caption07">Milestone 8 - Q2 2025</span>
          <div class="roadmap-heading08">
            <h2 class="roadmap-header16">Regulation &amp; Compliance</h2>
            <p class="roadmap-header17"><span>Register the aDeal network landing company in Singapore, to ensure
                compliance with local and international regulations. Implement comprehensive compliance measures to meet
                the regulatory requirements for running a web3-based advertising platform.</span><br /></p>
          </div>
        </div>
        <div class="roadmap-step08"><span class="roadmap-caption08">Milestone 9 - Q3 2025</span>
          <div class="roadmap-heading09">
            <h2 class="roadmap-header18">IDO Launchpad</h2>
            <p class="roadmap-header19">Conduct the IDO on IDO Launchpad, provide market makers programs, increase the
              token's liquidity, and engage a broader community base.
            </p>
          </div>
        </div>
        <div class="roadmap-step09"><span class="roadmap-caption09">Milestone 10 - Q4 2025 <span
              v-html="rawhckp"></span></span>
          <div class="roadmap-heading10">
            <h2 class="roadmap-header20">Post-Launch Growth and Sustainability</h2>
            <p class="roadmap-header21">Continue support and expansion efforts. Ongoing development based
              on user feedback and market needs. Strengthen and expand the
              ecosystem with new features and partnerships.</p>
          </div>
        </div>
      </div>
    </section>
    <aDealFooter></aDealFooter>
  </div>
</template>

<script>
import aDealHeader from './adeal-header'
import aDealFooter from './adeal-footer'

export default {
  components: {
    aDealHeader,
    aDealFooter
  },
  name: 'Roadmap',
  props: {},
  data() {
    return {
      rawwfwe: ' ',
      rawhckp: ' ',
    }
  },
  metaInfo: {
    title: 'Roadmap - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'Roadmap - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
  methods: {
    goBackersProgram() {
      window.open('/backers-program');
    },
  },
}
</script>

<style scoped>
.roadmap-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}

.roadmap-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.roadmap-logo {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.roadmap-desktop-menu {
  display: flex;
}

.roadmap-nav {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.roadmap-button {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.roadmap-button1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.roadmap-button2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.roadmap-button3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.roadmap-button4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.roadmap-btn-group {
  gap: var(--dl-space-space-fourunits);
  width: 233px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.roadmap-view {
  width: 201px;
}

.roadmap-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}

.roadmap-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.roadmap-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.roadmap-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.roadmap-logo1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.roadmap-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.roadmap-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.roadmap-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-text {
  margin-bottom: var(--dl-space-space-unit);
}

.roadmap-text01 {
  margin-bottom: var(--dl-space-space-unit);
}

.roadmap-text02 {
  margin-bottom: var(--dl-space-space-unit);
}

.roadmap-text03 {
  margin-bottom: var(--dl-space-space-unit);
}

.roadmap-text04 {
  margin-bottom: var(--dl-space-space-unit);
}

.roadmap-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}

.roadmap-login {
  margin-right: var(--dl-space-space-twounits);
}

.roadmap-icon-group {
  display: flex;
}

.roadmap-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.roadmap-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.roadmap-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.roadmap-collection {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}

.roadmap-content {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-heading {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  max-width: auto;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header {
  color: rgb(255, 255, 255);
  width: 1039px;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}

.roadmap-header01 {
  color: rgb(255, 255, 255);
}

.roadmap-roadmap {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}

.roadmap-list {
  gap: 106px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  border-left-width: 1px;
}

.roadmap-step {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption {
  color: rgb(255, 207, 119);
}

.roadmap-heading01 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header02 {
  color: rgb(255, 255, 255);
}

.roadmap-header03 {
  color: rgb(255, 255, 255);
}

.roadmap-step01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption01 {
  color: rgb(255, 207, 119);
}

.roadmap-heading02 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header04 {
  color: rgb(255, 255, 255);
}

.roadmap-header05 {
  color: rgb(255, 255, 255);
}

.roadmap-step02 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption02 {
  color: rgb(255, 207, 119);
}

.roadmap-heading03 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header06 {
  color: rgb(255, 255, 255);
}

.roadmap-button6 {
  margin-top: var(--dl-space-space-oneandhalfunits);
}

.roadmap-header07 {
  color: rgb(255, 255, 255);
}

.roadmap-navlink {
  text-decoration: underline;
}

.roadmap-step03 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption03 {
  color: rgb(255, 207, 119);
}

.roadmap-heading04 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header08 {
  color: rgb(255, 255, 255);
}

.roadmap-header09 {
  color: rgb(255, 255, 255);
}

.roadmap-step04 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption04 {
  color: rgb(255, 207, 119);
}

.roadmap-heading05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header10 {
  color: rgb(255, 255, 255);
}

.roadmap-header11 {
  color: rgb(255, 255, 255);
}

.roadmap-step05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption05 {
  color: rgb(255, 207, 119);
}

.roadmap-heading06 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header12 {
  color: rgb(255, 255, 255);
}

.roadmap-header13 {
  color: rgb(255, 255, 255);
}

.roadmap-step06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption06 {
  color: rgb(255, 207, 119);
}

.roadmap-heading07 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header14 {
  color: rgb(255, 255, 255);
}

.roadmap-header15 {
  color: rgb(255, 255, 255);
}

.roadmap-step07 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption07 {
  color: rgb(255, 207, 119);
}

.roadmap-heading08 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header16 {
  color: rgb(255, 255, 255);
}

.roadmap-header17 {
  color: rgb(255, 255, 255);
}

.roadmap-step08 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption08 {
  color: rgb(255, 207, 119);
}

.roadmap-heading09 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header18 {
  color: rgb(255, 255, 255);
}

.roadmap-header19 {
  color: rgb(255, 255, 255);
}

.roadmap-step09 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-caption09 {
  color: rgb(255, 207, 119);
}

.roadmap-heading10 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-header20 {
  color: rgb(255, 255, 255);
}

.roadmap-header21 {
  color: rgb(255, 255, 255);
}

.roadmap-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}

.roadmap-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.roadmap-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-heading11 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-logo2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.roadmap-caption10 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.roadmap-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.roadmap-twitter {
  text-decoration: none;
}

.roadmap-image {
  width: 100px;
  object-fit: cover;
}

.roadmap-discord {
  text-decoration: none;
}

.roadmap-image1 {
  width: 100px;
  object-fit: cover;
}

.roadmap-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.roadmap-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-heading12 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.roadmap-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.roadmap-list2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-heading13 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.roadmap-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.roadmap-link1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.roadmap-link2 {
  color: rgb(255, 255, 255);
}

.roadmap-link3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.roadmap-link4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.roadmap-socials1 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.roadmap-twitter1 {
  text-decoration: none;
}

.roadmap-image2 {
  width: 100px;
  object-fit: cover;
}

.roadmap-discord1 {
  text-decoration: none;
}

.roadmap-image3 {
  width: 100px;
  object-fit: cover;
}

.roadmap-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

@media(max-width: 991px) {
  .roadmap-desktop-menu {
    display: none;
  }

  .roadmap-btn-group {
    display: none;
  }

  .roadmap-burger-menu {
    display: flex;
  }

  .roadmap-button5 {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: row;
  }

  .roadmap-logo1 {
    color: #000000;
  }
}

@media(max-width: 767px) {
  .roadmap-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .roadmap-collection {
    gap: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-heading {
    gap: var(--dl-space-space-unit);
  }

  .roadmap-header {
    font-size: 32px;
    letter-spacing: 0;
  }

  .roadmap-header01 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-roadmap {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .roadmap-list {
    gap: var(--dl-space-space-threeunits);
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }

  .roadmap-heading01 {
    gap: var(--dl-space-space-unit);
  }

  .roadmap-header02 {
    font-size: 32px;
  }

  .roadmap-header03 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-heading02 {
    gap: var(--dl-space-space-unit);
  }

  .roadmap-header04 {
    font-size: 32px;
  }

  .roadmap-header05 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-heading03 {
    gap: var(--dl-space-space-unit);
  }

  .roadmap-header06 {
    font-size: 32px;
  }

  .roadmap-button6 {
    margin-top: 0px;
  }

  .roadmap-header07 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-heading04 {
    gap: var(--dl-space-space-unit);
  }

  .roadmap-header08 {
    font-size: 32px;
  }

  .roadmap-header09 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-heading05 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-header10 {
    font-size: 32px;
  }

  .roadmap-header11 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-text28 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-heading06 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-header12 {
    font-size: 32px;
  }

  .roadmap-header13 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-heading07 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-header14 {
    font-size: 32px;
  }

  .roadmap-header15 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-heading08 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-header16 {
    font-size: 32px;
  }

  .roadmap-header17 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-heading09 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-header18 {
    font-size: 32px;
  }

  .roadmap-header19 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-heading10 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-header20 {
    font-size: 32px;
  }

  .roadmap-header21 {
    font-size: 14px;
    line-height: 21px;
  }

  .roadmap-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }

  .roadmap-heading11 {
    gap: var(--dl-space-space-unit);
  }

  .roadmap-logo2 {
    font-size: 20px;
    line-height: 24px;
  }

  .roadmap-caption10 {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }

  .roadmap-socials {
    display: none;
  }

  .roadmap-links {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }

  .roadmap-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-list2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .roadmap-socials1 {
    display: flex;
  }
}

@media(max-width: 479px) {
  .roadmap-navbar {
    padding: var(--dl-space-space-unit);
  }

  .roadmap-mobile-menu {
    padding: 16px;
  }
}
</style>
