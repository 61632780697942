<template>
  <div class="verify-your-signature-container">
    <section class="verify-your-signature-cards">
      <h2 class="verify-your-signature-header">Verify your Signature</h2>
      <div class="verify-your-signature-content">
        <p class="verify-your-signature-description">
          Please share your support for aDeal on Twitter, return back to this page
          afterwards to complete verification.
        </p>
        <router-link
          to="/create-wishlist"
          class="verify-your-signature-navlink button"
        >
          Post Proof on Twitter
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'VerifyYourSignature',
  metaInfo: {
    title: 'VerifyYourSignature - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'VerifyYourSignature - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
}
</script>

<style scoped>
.verify-your-signature-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.verify-your-signature-cards {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.verify-your-signature-header {
  color: #ffffff;
  width: 622px;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.verify-your-signature-content {
  gap: var(--dl-space-space-unit);
  width: 697px;
  display: flex;
  max-width: 460px;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.verify-your-signature-description {
  color: var(--dl-color-gray-white);
  width: 543px;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}
.verify-your-signature-navlink {
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
@media(max-width: 767px) {
  .verify-your-signature-cards {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .verify-your-signature-header {
    font-size: 24px;
  }
  .verify-your-signature-description {
    font-size: 14px;
    line-height: 21px;
  }
  .verify-your-signature-navlink {
    margin-top: 0px;
  }
}
@media(max-width: 479px) {
  .verify-your-signature-header {
    width: 479px;
    height: 29px;
  }
}
</style>
