<template>
  <div class="sign-open-letter2-container">
    <section class="sign-open-letter2-hero">
      <div class="sign-open-letter2-heading">
        <h1 class="sign-open-letter2-header">Open Letter</h1>
        <p class="sign-open-letter2-caption">
          <span>To Our Esteemed Community,</span>
          <br />
          <br />
          <span>
            As we embark on this innovative journey with aDeal, a decentralized
            online advertising network, we invite you to join us in transforming
            the landscape of digital advertising. Our mission is to combat the
            challenges of digital ad fraud, leveraging blockchain technology to
            ensure transparency and fairness.
          </span>
          <br />
          <br />
          <span>
            By signing this letter with your Metamask, sharing post on X(twitter)
            account, you are not just supporting a project; you are becoming a
            part of a movement that values integrity, security, and efficiency in
            online advertising.
          </span>
          <br />
          <br />
          <span>
            We are excited to airdrop aDeal governance tokens as a token of
            appreciation for your support, especially to those who help spread the
            word through Twitter. This is more than a token gesture; it&apos;s our
            way of saying thank you for being the early believers in a vision that
            promises to redefine digital advertising.
          </span>
          <br />
          <br />
          <span>
            Together, let&apos;s build a network that not only serves the
            advertising industry but also empowers its participants.
          </span>
          <br />
          <br />
          <span>Sincerely,</span>
          <br />
          <br />
          <span>aDeal Development Team</span>
        </p>
      </div>
      <div class="sign-open-letter2-buttons">
        <router-link
          to="/verify-your-signature"
          class="sign-open-letter2-view button"
        >
          Please click here to sign
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SignOpenLetter2',
  metaInfo: {
    title: 'SignOpenLetter2 - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'SignOpenLetter2 - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
}
</script>

<style scoped>
.sign-open-letter2-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.sign-open-letter2-hero {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.sign-open-letter2-heading {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sign-open-letter2-header {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}
.sign-open-letter2-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  text-align: center;
  line-height: 36px;
}
.sign-open-letter2-buttons {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.sign-open-letter2-view {
  text-decoration: none;
}
@media(max-width: 767px) {
  .sign-open-letter2-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .sign-open-letter2-heading {
    gap: var(--dl-space-space-unit);
  }
  .sign-open-letter2-header {
    font-size: 36px;
    max-width: 70%;
    line-height: 43px;
  }
  .sign-open-letter2-caption {
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
