import {keccak256} from './ethereumjs-util/hash.js';

// const EthJS = require('ethereumjs-util');  //npm start not work
var Buffer = require('buffer/').Buffer
import {DidApi} from './did-api.js';
// import {successMessage, errorMessage} from './message/message';

export {connectMetaMask, signForRegister};
window.connectMetaMask= connectMetaMask;
window.signForRegister= signForRegister;
window.getEncryptionPublicKey= getEncryptionPublicKey;
window.getUserInfo= getUserInfo;
window.getDidApiVersion= getDidApiVersion;

window.Buffer = Buffer;

var encryptionPublicKey;

let did_register_sign_message_twitter= "Excited to join the #aDeal #Web3 Revolution! Let's reshape advertising together! Sign here: https://adeal.network/"

// async function getPermission() {
//   const permissions = await ethereum.request({ method: 'wallet_getPermissions' });
//   console.log('getPermission', permissions)
// }

function errorMessage(msg) {
  console.error(msg);
}

window.matamaskInstalled= false;
window.addEventListener('load', function() {
  if (window.ethereum) {
    console.log('Ethereum support is available')
    if (window.ethereum.isMetaMask) {
      console.log('MetaMask is active')
      window.matamaskInstalled= true;
    } else {
      console.log('MetaMask is not available')
    }
  } else {
    //插件未啟用或其他原因
    console.log('Ethereum support is not found')
  }
})

function connectMetaMask(handleAccountsChanged) {
  console.log('connect...');

  ethereum
    .request({ method: 'eth_requestAccounts' })
    .then(handleAccountsChanged)
    .catch((error) => {
      if (error.code === 4001) {
        // EIP-1193 userRejectedRequest error
        console.log('Please connect to MetaMask.');
      } else {
        console.error(error);
      }
    });
}

async function getEncryptionPublicKey(account) {
  ethereum
  .request({
    method: 'eth_getEncryptionPublicKey',
    params: [account], // you must have access to the specified account
  })
  .then((result) => {
    encryptionPublicKey = result;
    console.log('encryptionPublicKey', encryptionPublicKey)

    const publicKey = Buffer.from(encryptionPublicKey, 'base64')
    console.log("publicKey:" , publicKey.toString("HEX"));

    // let pub= Buffer.from(base64Decode(encryptionPublicKey),'utf8');
    // console.log('pub= ', pub.toString('hex'));
  })
  .catch((error) => {
    if (error.code === 4001) {
      // EIP-1193 userRejectedRequest error
      console.log("We can't encrypt anything without the key.");
    } else {
      console.error(error);
    }
  });
}

async function encryptPlain(plain) {
  console.log("plain", plain);
  
  const encryptedMessage = EthJS.bufferToHex(
    Buffer.from(
      JSON.stringify(
        sigUtil.encrypt({
          publicKey: encryptionPublicKey,
          data: plain,
          version: 'x25519-xsalsa20-poly1305',
        })
      ),
      'utf8'
    )
  );
  console.log("encryptedMessage", encryptedMessage);
}

async function signMessage(account, message, needHash) {
  console.log("message", message);

  // let message = web3.utils.utf8ToHex(n);
  let hex= Buffer.from(message,'utf8');
  // console.log('hex', hex)

  let sign;
  try {
    var msg;
    // if(!needHash) msg= `0x${hex.toString('hex')}`;
    // else msg= `0x${keccak256(hex)}`;
    if(!needHash) msg= `0x${hex.toString('hex')}`;
    else msg= `0x${keccak256(hex)}`;
    // console.log('msg', msg)

    sign = await ethereum.request({
      method: 'personal_sign',
      params: [msg, account],
    });
    console.log('sign', sign)
  } catch (err) {
    console.error(err);
  }
  return sign;
}

// base 64 URL encoding 
function base64EncodeUrl(str){
  return window.btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
}

async function generateJwt(address) {
  // JWT header 
  var header = {
    alg: "ES256",
    typ: "JWT"
  };

  // JWT body 
  var token = {
    sub: address, //主題
    // aud: "https://AAAS_PLATFORM/idp/YOUR_TENANT/authn/token",
    nbf: Math.floor(Date.now() / 1000) - 30,  //有效時間
    iss: address, //發行人
    exp: Math.floor(Date.now() / 1000) + (60 * 60), //到期時間
    // jti: uuidv1(),  //JWT unique ID, e.g use uuidv1.js
    iat: Math.floor(Date.now() / 1000) - 30 //發布時間
  };

  var raw= base64EncodeUrl(JSON.stringify(header)) + "." + base64EncodeUrl(JSON.stringify( token));
  console.log("raw", raw);
  var signature= await signMessage(address, raw, false);
  console.log("generateJwt:signature", signature);
  if(!signature) return null;

  return raw + "." + base64EncodeUrl(signature);
}

function getDidApiVersion() {

  DidApi.get_version(function(err, res) {
    if(err) {   
        if(err.xhr) errorMessage(err.textStatus);
        else if(err.error) errorMessage(err.msg);
    }
    else {
        console.log("getDidApiVersion", res);
    }
  });
}

async function getUserInfo(address) {
  let jwt= await generateJwt(address);
  console.log("getUserInfo:jwt", jwt);
  if(!jwt) return [-1, "No signature"];

  const [error, data]= DidApi.did_get_user(jwt)
  console.log("getUserInfo:error", error);
  console.log("getUserInfo:data", data);
  return [error, data];
}

async function signForRegister(account) {
  let signature= await signMessage(account, did_register_sign_message_twitter, false);
  console.log("signForRegister", signature);
  if(!signature) return null;

  // getEncryptionPublicKey(account);
  return did_register_sign_message_twitter + " sig:" + signature;
}
