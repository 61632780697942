<template>
  <div class="create-wishlist2-container">
    <section class="create-wishlist2-cards">
      <h2 class="create-wishlist2-header">That&apos;s great!</h2>
      <p class="create-wishlist2-description">
        Stay tuned for our aDeal token airdrop between February and March 2024 as
        a token of our appreciation!
      </p>
      <p class="create-wishlist2-description1">
        Have you participated in the Giveth aDeal project donation? If
        &apos;Yes&apos;, please provide your wallet address for additional aDeal
        token rewards during our airdrop ^^
      </p>
      <input
        type="text"
        placeholder="your wallet address"
        class="create-wishlist2-textinput input"
      />
      <button class="create-wishlist2-button button">Submit</button>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CreateWishlist2',
  metaInfo: {
    title: 'CreateWishlist2 - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'CreateWishlist2 - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
}
</script>

<style scoped>
.create-wishlist2-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.create-wishlist2-cards {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.create-wishlist2-header {
  color: rgb(255, 255, 255);
  width: 525px;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.create-wishlist2-description {
  color: var(--dl-color-gray-white);
  width: 504px;
  height: 93px;
  font-size: 18px;
  max-width: auto;
  line-height: 27px;
  margin-right: 1px;
}
.create-wishlist2-description1 {
  color: var(--dl-color-gray-white);
  width: 513px;
  height: 93px;
  font-size: 18px;
  max-width: auto;
  line-height: 27px;
  margin-right: 1px;
}
.create-wishlist2-textinput {
  width: 413px;
}
.create-wishlist2-button {
  margin-top: var(--dl-space-space-oneandhalfunits);
}
@media(max-width: 767px) {
  .create-wishlist2-cards {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .create-wishlist2-header {
    font-size: 24px;
  }
  .create-wishlist2-description {
    font-size: 14px;
    line-height: 21px;
  }
  .create-wishlist2-description1 {
    font-size: 14px;
    line-height: 21px;
  }
  .create-wishlist2-button {
    margin-top: 0px;
  }
}
@media(max-width: 479px) {
  .create-wishlist2-header {
    width: 479px;
    height: 26px;
  }
  .create-wishlist2-description {
    width: 479px;
  }
  .create-wishlist2-description1 {
    width: 479px;
  }
}
</style>
