<template>
  <div class="white-paper-container">
    <aDealHeader></aDealHeader>

    <section class="white-paper-collection">
      <div class="white-paper-content">
        <div class="white-paper-heading">
          <h2 class="white-paper-header">
            <span>Download aDeal Network Documents</span>
            <br />
          </h2>
          <p class="white-paper-header1">
            <span>
              Here you&apos;ll find all the essential documents related to the
              aDeal Network project
            </span>
            <br />
            <br />
          </p>
        </div>
      </div>
      <div class="white-paper-feature-card">
        <h2 class="white-paper-text10">White-paper</h2>
        <span class="white-paper-text11">
          Our comprehensive whitepaper outlines the vision, technology, and
          roadmap for aDeal Network.
        </span>
        <a
          href="/aDeal Network Whitepaper.pdf"
          target="_blank"
          rel="noreferrer noopener"
          class="white-paper-link"
        >
          Download
        </a>
      </div>
      <div class="white-paper-feature-card1">
        <h2 class="white-paper-text12">Light-paper</h2>
        <span class="white-paper-text13">
          Shorter and more digestible version of the whitepaper
        </span>
        <a
          href="/aDeal network light-paper.pdf"
          target="_blank"
          rel="noreferrer noopener"
          class="white-paper-link1"
        >
          Download
        </a>
      </div>
      <div class="white-paper-feature-card2">
        <h2 class="white-paper-text14">aDeal network pitch deck</h2>
        <span class="white-paper-text15">
          Get a quick overview of the project with our concise pitch deck
        </span>
        <a
          href="/aDeal network Introduction.pdf"
          target="_blank"
          rel="noreferrer noopener"
          class="white-paper-link2"
        >
          Download
        </a>
      </div>
    </section>
    
    <aDealFooter></aDealFooter>
  </div>
</template>

<script>
import aDealHeader from './adeal-header'
import aDealFooter from './adeal-footer'

export default {
  components: {
    aDealHeader,
    aDealFooter
  },
  name: 'WhitePaper',
  props: {},
  metaInfo: {
    title: 'WhitePaper - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'WhitePaper - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
}
</script>

<style scoped>
.white-paper-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.white-paper-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.white-paper-logo {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.white-paper-desktop-menu {
  display: flex;
}
.white-paper-nav {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.white-paper-button {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.white-paper-button1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.white-paper-button2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.white-paper-button3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.white-paper-button4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.white-paper-btn-group {
  gap: var(--dl-space-space-fourunits);
  width: 233px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.white-paper-view {
  width: 201px;
}
.white-paper-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.white-paper-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.white-paper-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.white-paper-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.white-paper-logo1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.white-paper-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.white-paper-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.white-paper-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-text {
  margin-bottom: var(--dl-space-space-unit);
}
.white-paper-text01 {
  margin-bottom: var(--dl-space-space-unit);
}
.white-paper-text02 {
  margin-bottom: var(--dl-space-space-unit);
}
.white-paper-text03 {
  margin-bottom: var(--dl-space-space-unit);
}
.white-paper-text04 {
  margin-bottom: var(--dl-space-space-unit);
}
.white-paper-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.white-paper-login {
  margin-right: var(--dl-space-space-twounits);
}
.white-paper-icon-group {
  display: flex;
}
.white-paper-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.white-paper-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.white-paper-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.white-paper-collection {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.white-paper-content {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-heading {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  max-width: auto;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-header {
  color: rgb(255, 255, 255);
  width: 1039px;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}
.white-paper-header1 {
  color: rgb(255, 255, 255);
}
.white-paper-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.white-paper-text10 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.white-paper-text11 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.white-paper-link {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-decoration: none;
}
.white-paper-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.white-paper-text12 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.white-paper-text13 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.white-paper-link1 {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-decoration: none;
}
.white-paper-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.white-paper-text14 {
  color: rgb(255, 255, 255);
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.white-paper-text15 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.white-paper-link2 {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-decoration: none;
}
.white-paper-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.white-paper-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.white-paper-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-heading1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-logo2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.white-paper-caption {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.white-paper-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.white-paper-twitter {
  text-decoration: none;
}
.white-paper-image {
  width: 100px;
  object-fit: cover;
}
.white-paper-discord {
  text-decoration: none;
}
.white-paper-image1 {
  width: 100px;
  object-fit: cover;
}
.white-paper-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.white-paper-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-heading2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.white-paper-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-link3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.white-paper-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-heading3 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.white-paper-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.white-paper-link4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.white-paper-link5 {
  color: rgb(255, 255, 255);
}
.white-paper-link6 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.white-paper-link7 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.white-paper-socials1 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.white-paper-twitter1 {
  text-decoration: none;
}
.white-paper-image2 {
  width: 100px;
  object-fit: cover;
}
.white-paper-discord1 {
  text-decoration: none;
}
.white-paper-image3 {
  width: 100px;
  object-fit: cover;
}
.white-paper-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
@media(max-width: 991px) {
  .white-paper-desktop-menu {
    display: none;
  }
  .white-paper-btn-group {
    display: none;
  }
  .white-paper-burger-menu {
    display: flex;
  }
  .white-paper-button5 {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .white-paper-logo1 {
    color: #000000;
  }
}
@media(max-width: 767px) {
  .white-paper-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .white-paper-collection {
    gap: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .white-paper-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .white-paper-heading {
    gap: var(--dl-space-space-unit);
  }
  .white-paper-header {
    font-size: 32px;
    letter-spacing: 0;
  }
  .white-paper-header1 {
    font-size: 14px;
    line-height: 21px;
  }
  .white-paper-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .white-paper-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .white-paper-heading1 {
    gap: var(--dl-space-space-unit);
  }
  .white-paper-logo2 {
    font-size: 20px;
    line-height: 24px;
  }
  .white-paper-caption {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }
  .white-paper-socials {
    display: none;
  }
  .white-paper-links {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }
  .white-paper-list {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .white-paper-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .white-paper-socials1 {
    display: flex;
  }
}
@media(max-width: 479px) {
  .white-paper-navbar {
    padding: var(--dl-space-space-unit);
  }
  .white-paper-mobile-menu {
    padding: 16px;
  }
}
</style>
