<template>
  <div class="bakers-program-container">
    <aDealHeader></aDealHeader>

    <section class="bakers-program-collection">
      <div class="bakers-program-content">
        <div class="bakers-program-heading">
          <p class="bakers-program-header1">
            <span class="bakers-program-text089"><span class="bakers-program-text089">The Backers Program is open for community members to sign an open letter online. This letter is a statement of support and interest in the aDeal Network. Once we have gathered sufficient support, we will announce the official token sale date.</span><br /><br /></span><br /><br />
            <!-- <span class="bakers-program-header1">Become a Backer and secure your spot in the aDeal Network Backers Program.</span><br />
            <span class="bakers-program-header1">Time Slot: June 12, 2024 - July 3, 2024</span><br /> -->
            <router-link to="/terms-conditions" class="bakers-program-navlink3 button">Become a Backer</router-link>
          </p>

          <h2 class="bakers-program-header">Backers Program</h2>
          <p class="bakers-program-header1"><span>Last Updated: April 25, 2024</span><br /><br /><span>The aDeal network
              &quot;Backers Program&quot; is a strategic
              initiative designed to engage early supporters of the project,
              offering them an opportunity to participate in the network&apos;s
              development both financially and influentially. This program is
              structured to facilitate funding by distributing a portion of ADV
              tokens at an initial favorable price, aligning the interests of
              backers with the long-term success of the aDeal network.</span><br /><br /><span
              class="bakers-program-text011">Overview of the Backers
              Program</span><br /><br /><span>Objective:</span><br /><br /><span>The primary objective of the Backers
              Program is to secure
              early-stage funding for continued development and operation of the
              aDeal network, while establishing a committed community base that
              can provide ongoing support and feedback.</span><br /><br /><span>Token
              Allocation:</span><br /><br /><span>The program allocates 5% of the total ADV token fixed supply 1
              billion tokens, which is 50 million ADV tokens. specifically for
              early backers. This equates to The details of ADV token distribution
              <span v-html="raw2ef4"></span></span><router-link to="/white-paper" class="bakers-program-navlink">please
              reference the white paper here.</router-link><br /><br />
              
              <span>Early Backers Token Price: 1 OP = 120 ADV</span><br />
              <span>Token Purchase: Backers can purchase ADV tokens using Optimism mainnet OP.</span><br />
              <span>Use of Funds: Funds raised will be managed by aDeal DAO for project development.</span><br /><br />
              <span>Funding Goal:</span><br />
              <span>Soft Cap: 87,500 OP</span><br />
              <span>Hard Cap: 416,666 OP</span><br /><br />

              <span>Pricing and Time Slots:</span><br />
              <span>Supply: 50 million ADV (5% of total tokens)</span><br />
              <span>Token Price: 1 OP = 120 ADV</span><br />
              <!-- <span>Time Slot: June 12, 2024 - July 3, 2024</span><br /><br /> -->
              
              <span> These funds will be used to:</span><br /><br /><span>1. Finance the
              development of the Minimum Viable Product
              (MVP).</span><br /><span>2. Cover operational costs including marketing, legal, and
              compliance expenses.</span><br /><span>3. Fund research and development for ongoing feature
              enhancements.</span><br /><br /><span class="bakers-program-text052">Benefits for
              Backers</span><br /><br /><span>Early Financial Incentive - Backers benefit from purchasing the ADV
              tokens at an early bird price, which is anticipated to be lower than
              future valuations as the project reaches maturity and wider
              adoption.</span><br /><br /><span>Staking ADV to Mint ADP - Incentive for Staking. By staking ADV
              tokens, backers can mint ADP tokens. This process not only supports
              the network&apos;s security and stability but also provides backers
              with a direct mechanism to participate in and benefit from the
              network&apos;s advertising economy. The staking process acts as a
              reward mechanism, where backers are compensated for their
              participation and support of the network through the receipt of ADP
              tokens, which they can use or sell. (</span><router-link to="/roadmap"
              class="bakers-program-navlink1">Please reference the development
              roadmap</router-link><span>)</span><br /><br /><span>Influence and Participation - Backers are not just
              investors; they
              are community members who have a say in the project&apos;s
              development. They gain early access to the project&apos;s
              development stages and have the opportunity to influence decisions
              through structured feedback mechanisms and participation in the
              aDeal DAO.</span><br /><br /><span>Long-Term Growth Potential - By supporting the project early,
              backers potentially benefit from the long-term success of the aDeal
              network as the platform grows, the user base expands, and the
              utility of the ADV token increases.</span><br /><br /><span class="bakers-program-text068">Considerations
              for Potential Backers</span><br /><br /><span>Risk Assessment</span><br /><br /><span>As with any
              investment, especially in the volatile and rapidly
              evolving field of blockchain and cryptocurrencies, potential backers
              should perform due diligence and assess the risks associated with
              the project.</span><br /><br /><span>Regulatory Compliance</span><br /><br /><span>The aDeal network
              promises to handle all regulatory compliance,
              ensuring that the fundraising and token distribution are conducted
              in accordance with applicable laws and regulations, which is crucial
              for protecting the interests of backers. Additionally, our KYC/KYB
              (Know Your Customer/Know Your Business) partner, Ondato, ensures
              thorough identity verification and compliance checks, adding an
              extra layer of security and trust to the process.</span><br /><br /><span
              class="bakers-program-text083">Engagement and Transparency</span><br /><br /><span>The aDeal network
              commits to maintaining high levels of
              transparency and engagement with its backers, providing regular
              updates and being clear about project milestones, challenges, and
              successes.</span><br /><br /><span class="bakers-program-text089">Join us Now!</span><br /><br /><span>The
              Backers Program is a cornerstone of the aDeal network&apos;s
              early development strategy, designed to build a robust and
              supportive community foundation while securing necessary funding for
              initial project milestones. It offers a balanced opportunity for
              early investors to participate in a potentially revolutionary
              blockchain-based advertising network, with a structure that aims to
              align the interests of the developers and the backers for mutual
              long-term benefit.</span><br /><br />
            </p>
        </div>
      </div>
    </section>
    <aDealFooter></aDealFooter>
  </div>
</template>

<script>
import aDealHeader from './adeal-header'
import aDealFooter from './adeal-footer'

export default {
  components: {
    aDealHeader,
    aDealFooter
  },
  name: 'BakersProgram',
  props: {},
  data() {
    return {
      raw2ef4: ' ',
      rawbe8h: ' ',
      raw177q: ' ',
      rawkyks: ' ',
      rawld5q: ' ',
      rawxvrv: ' ',
    }
  },
  metaInfo: {
    title: 'BackersProgram - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'BackersProgram - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
  data(){
    return{
      // metamask_address:'',
    }
	},
  methods:{
    // isMobile() {
    //   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // connect() {
    //   if(this.metamask_address.length > 0) {
    //     this.checkUser(his.metamask_address);
    //     return;
    //   }
    //   window.connectMetaMask(this.handleAccountsChanged);
    // },
    // handleAccountsChanged(accounts) {
    //   console.log('handleAccountsChanged', accounts)
    //   this.metamask_address = accounts[0];
    //   this.checkUser(this.metamask_address);
    // },
    // checkUser(address) {
    //   getUserInfo(address);
    //   // 
    //   // window.open('/sign-open-letter');
    // },
    goPurchase() {
      // if(this.isMobile()) {
      //   alert("Please use your PC browser to open this page and connect with Metamask");
      //   return;
      // }
      // this.connect();

      window.open('/terms-conditions');
    },
  },
  mounted(){
    // getDidApiVersion();
  },
}
</script>

<style scoped>
.bakers-program-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}

.bakers-program-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.bakers-program-logo {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.bakers-program-desktop-menu {
  display: flex;
}

.bakers-program-nav {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.bakers-program-button {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.bakers-program-button1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.bakers-program-button2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.bakers-program-button3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.bakers-program-button4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.bakers-program-btn-group {
  gap: var(--dl-space-space-fourunits);
  width: 233px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.bakers-program-view {
  width: 201px;
}

.bakers-program-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}

.bakers-program-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.bakers-program-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.bakers-program-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.bakers-program-logo1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.bakers-program-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bakers-program-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.bakers-program-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-text {
  margin-bottom: var(--dl-space-space-unit);
}

.bakers-program-text001 {
  margin-bottom: var(--dl-space-space-unit);
}

.bakers-program-text002 {
  margin-bottom: var(--dl-space-space-unit);
}

.bakers-program-text003 {
  margin-bottom: var(--dl-space-space-unit);
}

.bakers-program-text004 {
  margin-bottom: var(--dl-space-space-unit);
}

.bakers-program-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}

.bakers-program-login {
  margin-right: var(--dl-space-space-twounits);
}

.bakers-program-icon-group {
  display: flex;
}

.bakers-program-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.bakers-program-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.bakers-program-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.bakers-program-collection {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}

.bakers-program-content {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-heading {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  max-width: auto;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-header {
  color: rgb(255, 255, 255);
  width: 642px;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}

.bakers-program-header1 {
  color: rgb(255, 255, 255);
}

.bakers-program-text011 {
  font-size: 24px;
}

.bakers-program-navlink {
  text-decoration: underline;
}

.bakers-program-text052 {
  font-size: 24px;
}

.bakers-program-navlink1 {
  text-decoration: underline;
}

.bakers-program-text068 {
  font-size: 24px;
}

.bakers-program-text083 {
  font-size: 24px;
}

.bakers-program-text089 {
  font-size: 24px;
}

.bakers-program-cards {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}

.bakers-program-header2 {
  color: rgb(255, 255, 255);
  width: 622px;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}

.bakers-program-content1 {
  gap: var(--dl-space-space-unit);
  width: 697px;
  display: flex;
  max-width: 460px;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-description {
  color: var(--dl-color-gray-white);
  width: 543px;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}

.bakers-program-description1 {
  color: var(--dl-color-gray-white);
  width: 543px;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}

.bakers-program-navlink2 {
  text-decoration: underline;
}

.bakers-program-navlink3 {
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}

.bakers-program-cards1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}

.bakers-program-header3 {
  color: rgb(255, 255, 255);
  width: 622px;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}

.bakers-program-content2 {
  gap: var(--dl-space-space-unit);
  width: 697px;
  display: flex;
  max-width: 460px;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-description2 {
  color: var(--dl-color-gray-white);
  width: 543px;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}

.bakers-program-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}

.bakers-program-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.bakers-program-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-heading1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-logo2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.bakers-program-caption {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.bakers-program-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.bakers-program-twitter {
  text-decoration: none;
}

.bakers-program-image {
  width: 100px;
  object-fit: cover;
}

.bakers-program-discord {
  text-decoration: none;
}

.bakers-program-image1 {
  width: 100px;
  object-fit: cover;
}

.bakers-program-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.bakers-program-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-heading2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.bakers-program-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.bakers-program-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-heading3 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.bakers-program-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.bakers-program-link1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.bakers-program-link2 {
  color: rgb(255, 255, 255);
}

.bakers-program-link3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.bakers-program-link4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.bakers-program-socials1 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.bakers-program-twitter1 {
  text-decoration: none;
}

.bakers-program-image2 {
  width: 100px;
  object-fit: cover;
}

.bakers-program-discord1 {
  text-decoration: none;
}

.bakers-program-image3 {
  width: 100px;
  object-fit: cover;
}

.bakers-program-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

@media(max-width: 991px) {
  .bakers-program-desktop-menu {
    display: none;
  }

  .bakers-program-btn-group {
    display: none;
  }

  .bakers-program-burger-menu {
    display: flex;
  }

  .bakers-program-button5 {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: row;
  }

  .bakers-program-logo1 {
    color: #000000;
  }
}

@media(max-width: 767px) {
  .bakers-program-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .bakers-program-collection {
    gap: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .bakers-program-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .bakers-program-heading {
    gap: var(--dl-space-space-unit);
  }

  .bakers-program-header {
    font-size: 32px;
    letter-spacing: 0;
  }

  .bakers-program-header1 {
    font-size: 14px;
    line-height: 21px;
  }

  .bakers-program-cards {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .bakers-program-header2 {
    font-size: 24px;
  }

  .bakers-program-description {
    font-size: 14px;
    line-height: 21px;
  }

  .bakers-program-description1 {
    font-size: 14px;
    line-height: 21px;
  }

  .bakers-program-navlink3 {
    margin-top: 0px;
  }

  .bakers-program-cards1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .bakers-program-header3 {
    font-size: 24px;
  }

  .bakers-program-description2 {
    font-size: 14px;
    line-height: 21px;
  }

  .bakers-program-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .bakers-program-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }

  .bakers-program-heading1 {
    gap: var(--dl-space-space-unit);
  }

  .bakers-program-logo2 {
    font-size: 20px;
    line-height: 24px;
  }

  .bakers-program-caption {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }

  .bakers-program-socials {
    display: none;
  }

  .bakers-program-links {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }

  .bakers-program-list {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .bakers-program-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .bakers-program-socials1 {
    display: flex;
  }
}

@media(max-width: 479px) {
  .bakers-program-navbar {
    padding: var(--dl-space-space-unit);
  }

  .bakers-program-mobile-menu {
    padding: 16px;
  }

  .bakers-program-header2 {
    width: 479px;
    height: 29px;
  }

  .bakers-program-header3 {
    width: 479px;
    height: 29px;
  }
}
</style>
