<template>
  <div class="privacy-container">
    <aDealHeader></aDealHeader>

    <section class="privacy-collection">
      <div class="privacy-content">
        <div class="privacy-heading">
          <h2 class="privacy-header">Privacy Policy of aDeal Network</h2>
          <p class="privacy-header1">
            <span>Last Updated: Nov 1, 2023</span>
            <br />
            <br />
            <span>
              Welcome to aDeal! We are committed to protecting your privacy and
              being transparent about how we handle your personal information.
              This Privacy Policy outlines how we collect, use, store, and share
              your information.
            </span>
            <br />
            <br />
            <span>1. Data Collection and Use</span>
            <br />
            <span>
              What We Collect: To provide our services, we collect minimal
              Personal Information necessary. This includes identifying
              information, account details, user content, cookie log data, and
              digital signatures.
            </span>
            <br />
            <span>
              Purpose of Collection: We use this information to ensure
              functionality, facilitate communication, provide updates, optimize
              our services, and comply with legal requirements.
            </span>
            <br />
            <br />
            <span>2. Decentralized Data Storage</span>
            <br />
            <span>
              Data Storage Approach: aDeal leverages decentralized data storage
              systems, meaning most of your data is stored with you, not on our
              servers.
            </span>
            <br />
            <span>
              User Control: You have complete control over your data and how it is
              shared within the network.
            </span>
            <br />
            <br />
            <span>3. Cookie Policy</span>
            <br />
            <span>
              Usage of Cookies: We use cookies for various purposes:
              functionality, security, analytics, and advertising.
            </span>
            <br />
            <span>
              User Control: You have the option to manage cookie settings through
              your browser.
            </span>
            <br />
            <br />
            <span>4. Sharing of Personal Information &amp; Wishlist</span>
            <br />
            <span>
              Consent-Based Sharing: Your information is shared only with your
              consent or as permitted by law.
            </span>
            <br />
            <span>
              Disclosure to Third Parties: This includes sharing with professional
              advisors, service providers, affiliates, and in compliance with
              legal obligations.
            </span>
            <br />
            <br />
            <span>5. Security Measures</span>
            <br />
            <span>
              Data Protection: We retain Personal Information only as long as
              necessary and protect it using industry-standard measures.
            </span>
            <br />
            <span>
              Transmission Security: While we strive for secure transmission,
              please be aware that no internet transmission is completely secure.
            </span>
            <br />
            <br />
            <span>6. User Rights</span>
            <br />
            <span>
              Access and Control: You have rights to review, correct, delete, or
              opt-out of sharing your Personal Information.
            </span>
            <br />
            <span>
              Additional Rights: Residents of California and the European Economic
              Area have further rights over their Personal Information.
            </span>
            <br />
            <br />
            <span>7. Children&apos;s Privacy</span>
            <br />
            <span>
              Age Limitation: Our services are not intended for children under 13,
              and we do not knowingly collect such data.
            </span>
            <br />
            <span>
              Guardian Consent: Minors require guardian consent to use our
              services.
            </span>
            <br />
            <br />
            <span>8. Policy Changes</span>
            <br />
            <span>
              Updates to Policy: This policy may be updated, and we encourage you
              to review it periodically.
            </span>
            <br />
            <span>
              Acceptance of Changes: Continued use of our services after changes
              indicates your acceptance of the new policy.
            </span>
            <br />
            <span>
              We value your trust and are dedicated to handling your personal
              information responsibly and with respect. Should you have any
              questions about this policy, please contact us at
              <span v-html="raww5j2"></span>
            </span>
            <a
              href="https://forms.gle/HQvn2deheGsCFteaA"
              target="_blank"
              rel="noreferrer noopener"
              class="privacy-link"
            >
              here
            </a>
            <span>.</span>
            <br />
            <br />
            <span>
              By using the aDeal Network, you acknowledge that you have read,
              understood, and agree to this Privacy Policy.
            </span>
            <br />
            <br />
          </p>
        </div>
      </div>
    </section>
    
    <aDealFooter></aDealFooter>
  </div>
</template>

<script>
import aDealHeader from './adeal-header'
import aDealFooter from './adeal-footer'

export default {
  components: {
    aDealHeader,
    aDealFooter
  },
  name: 'Privacy',
  data() {
    return {
      raww5j2: ' ',
    }
  },
  metaInfo: {
    title: 'Privacy - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'Privacy - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
}
</script>

<style scoped>
.privacy-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.privacy-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.privacy-logo {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.privacy-desktop-menu {
  display: flex;
}
.privacy-nav {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.privacy-button {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.privacy-button1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.privacy-button2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.privacy-button3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.privacy-button4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.privacy-btn-group {
  gap: var(--dl-space-space-fourunits);
  width: 233px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.privacy-view {
  width: 201px;
}
.privacy-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.privacy-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.privacy-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.privacy-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.privacy-logo1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.privacy-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacy-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.privacy-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-text {
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-text01 {
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-text02 {
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-text03 {
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-text04 {
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.privacy-login {
  margin-right: var(--dl-space-space-twounits);
}
.privacy-icon-group {
  display: flex;
}
.privacy-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.privacy-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.privacy-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.privacy-collection {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.privacy-content {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-heading {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  max-width: auto;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-header {
  color: rgb(255, 255, 255);
  width: 1039px;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}
.privacy-header1 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.privacy-link {
  text-decoration: underline;
}
.privacy-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.privacy-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-heading1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-logo2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.privacy-caption {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.privacy-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.privacy-twitter {
  text-decoration: none;
}
.privacy-image {
  width: 100px;
  object-fit: cover;
}
.privacy-discord {
  text-decoration: none;
}
.privacy-image1 {
  width: 100px;
  object-fit: cover;
}
.privacy-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.privacy-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-heading2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.privacy-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-link1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.privacy-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-heading3 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.privacy-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-link2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.privacy-link3 {
  color: rgb(255, 255, 255);
}
.privacy-link4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.privacy-link5 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.privacy-socials1 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.privacy-twitter1 {
  text-decoration: none;
}
.privacy-image2 {
  width: 100px;
  object-fit: cover;
}
.privacy-discord1 {
  text-decoration: none;
}
.privacy-image3 {
  width: 100px;
  object-fit: cover;
}
.privacy-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
@media(max-width: 991px) {
  .privacy-desktop-menu {
    display: none;
  }
  .privacy-btn-group {
    display: none;
  }
  .privacy-burger-menu {
    display: flex;
  }
  .privacy-button5 {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .privacy-logo1 {
    color: #000000;
  }
}
@media(max-width: 767px) {
  .privacy-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .privacy-collection {
    gap: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .privacy-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .privacy-heading {
    gap: var(--dl-space-space-unit);
  }
  .privacy-header {
    font-size: 32px;
    letter-spacing: 0;
  }
  .privacy-header1 {
    font-size: 14px;
    line-height: 21px;
  }
  .privacy-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .privacy-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .privacy-heading1 {
    gap: var(--dl-space-space-unit);
  }
  .privacy-logo2 {
    font-size: 20px;
    line-height: 24px;
  }
  .privacy-caption {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }
  .privacy-socials {
    display: none;
  }
  .privacy-links {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }
  .privacy-list {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .privacy-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .privacy-socials1 {
    display: flex;
  }
}
@media(max-width: 479px) {
  .privacy-navbar {
    padding: var(--dl-space-space-unit);
  }
  .privacy-mobile-menu {
    padding: 16px;
  }
}
</style>
