<template>
  <div class="purchase-container">
    <aDealHeader></aDealHeader>

    <div class="purchase-content">
      <div class="purchase-main">
        <h2 class="purchase-header">ADV Token Purchase Form</h2>
        <p class="purchase-caption">
          Use this form to purchase ADV tokens with your OP.
        </p>       
        
        <div class="row">
          <div class="col-md-16">
              <div class="payment-calculator">
                  <div class="payment-get">
                      <label for="paymentGet" class="purchase-caption1">OP Amount</label>
                      <div class="payment-input">
                          <input class="input-bordered" type="number" id="paymentGet" v-model.number="token1_amount" @input="input_token1">
                          <span class="payment-get-cur payment-cal-cur">OP</span>    
                      </div>
                  </div>
                  <em class="ti ti-exchange-vertical" style="color:#FFFFFF"></em>
                  <div class="payment-from">
                      <label for="paymentFrom" class="purchase-caption2">ADV Tokens</label>
                      <div class="payment-input">
                          <input class="input-bordered" type="number" id="paymentFrom" v-model.number="token2_amount" @input="input_token2">
                          <span class="payment-from-cur payment-cal-cur">ADV</span>    
                      </div>
                  </div>
              </div>
              <div class="gaps-2x d-md-none"></div>
          </div><!-- .col -->
        </div>

        <p class="purchase-caption3">
          <span>Early Backers Token Price: 1 OP = 120 ADV</span><br />
          <!-- <span>Time Slot: Starts at 00:00 GMT on June 12, 2024 - July 3, 2024</span><br /> -->
          <span>Let's build this together and make it a success! Your support is crucial in driving our mission forward.</span><br />
          <span>Please contact us at adeal@adeal.network to join the Backers Program</span><br />
          <span style="color:red">** The OP token is the native utility token of the Optimism ecosystem **</span><br />
        </p>
        

        <!-- <iframe src="https://tokentool.bitbond.com/tokensale/0x9B1e772B1C5be9dEb7e4e7F9fB46276D0DcEFE5E?chainId=11155111" width="100%" height="1000px" frameborder="0" name="tokensale"></iframe> -->
        
      </div>
    </div>
    <aDealFooter></aDealFooter>
  </div>
</template>

<script>
import aDealHeader from './adeal-header'
import aDealFooter from './adeal-footer'
// import {Web3} from "web3";

export default {
  components: {
    aDealHeader,
    aDealFooter
  },
  name: 'Purchase',
  props: {},
  metaInfo: {
    title: 'purchase - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'purchase - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
  data(){
    return{
      // chainName:'Sepolia Testnet', //Sepolia Testnet / Polygon Mainnet
      // chainId:11155111, //11155111:Sepolia test network / 137:Polygon Mainnet
      // nativeCurrency: 'SepoliaETH',  //SepoliaETH / MATIC
      // decimals: 18,      
      // rpcUrls: 'https://sepolia.infura.io/v3/',  //https://sepolia.infura.io/v3/ / https://polygon.llamarpc.com
      // explorer: 'https://sepolia.etherscan.io', //https://sepolia.etherscan.io / https://polygonscan.com/
      // purchase_contract:"0x786Db7F4f12839aA0C2cE16d8cbc38EC5C52fA20",
      exchange_rate: 120,
      token1_amount:1,
      token2_amount:1,
    }
  },
  methods:{
    input_token1(value) {
      console.log('input_token1', value)
      this.token2_amount= (this.token1_amount * this.exchange_rate).toFixed(10);
    },
    input_token2(value) {
      console.log('input_token2', value)
      this.token1_amount= (this.token2_amount / this.exchange_rate).toFixed(10);
    },
    // async initMetaMask() {
    //   if (window.ethereum.networkVersion !== this.chainId) {
    //     try {
    //       console.log("wallet_switchEthereumChain");

    //       await window.ethereum.request({
    //         method: 'wallet_switchEthereumChain',
    //         params: [{ chainId: Web3.utils.toHex(this.chainId) }]
    //       });
    //     } catch (err) {
    //         // This error code indicates that the chain has not been added to MetaMask
    //       if (err.code === 4902) {
    //         console.log("wallet_addEthereumChain");

    //         await window.ethereum.request({
    //           method: 'wallet_addEthereumChain',
    //           params: [
    //             {
    //               chainName: this.chainName,
    //               chainId: Web3.utils.toHex(this.chainId),
    //               nativeCurrency: { name: this.nativeCurrency, decimals: this.decimals, symbol: this.nativeCurrency },
    //               rpcUrls: [this.rpcUrls]
    //             }
    //           ]
    //         });
    //       }
    //     }
    //   }
    // },
  },
  mounted(){
    this.token2_amount= (this.token1_amount * this.exchange_rate).toFixed(10);

    // this.initMetaMask();
  },
}
</script>

<style scoped>
.purchase-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}

.purchase-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.purchase-logo {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.purchase-desktop-menu {
  display: flex;
}

.purchase-nav {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.purchase-button {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.purchase-button1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.purchase-button2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.purchase-button3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.purchase-button4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.purchase-btn-group {
  gap: var(--dl-space-space-fourunits);
  width: 233px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.purchase-view {
  width: 201px;
}

.purchase-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}

.purchase-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.purchase-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.purchase-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.purchase-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.purchase-logo1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.purchase-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.purchase-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.purchase-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.purchase-text {
  margin-bottom: var(--dl-space-space-unit);
}

.purchase-text01 {
  margin-bottom: var(--dl-space-space-unit);
}

.purchase-text02 {
  margin-bottom: var(--dl-space-space-unit);
}

.purchase-text03 {
  margin-bottom: var(--dl-space-space-unit);
}

.purchase-text04 {
  margin-bottom: var(--dl-space-space-unit);
}

.purchase-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}

.purchase-login {
  margin-right: var(--dl-space-space-twounits);
}

.purchase-icon-group {
  display: flex;
}

.purchase-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.purchase-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.purchase-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.purchase-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-top: 43px;
  flex-direction: column;
}

.purchase-main {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.purchase-header {
  color: #fffdfd;
  width: 592px;
  height: 53px;
  font-size: 32px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 24px;
}

.purchase-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  line-height: 36px;
}

.purchase-heading {
  gap: var(--dl-space-space-twounits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.purchase-heading1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.purchase-caption1 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  line-height: 36px;
}

.purchase-textinput {
  height: 44px;
}

.purchase-heading2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.purchase-caption2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  line-height: 36px;
}

.purchase-textinput1 {
  height: 44px;
}

.purchase-button6 {
  width: 130px;
}

.purchase-caption3 {
  color: rgb(255, 255, 255);
  height: 125px;
  font-size: 18px;
  max-width: 800px;
  line-height: 36px;
}

.purchase-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}

.purchase-main1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.purchase-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}

.purchase-heading3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.purchase-logo2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.purchase-caption4 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.purchase-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.purchase-twitter {
  text-decoration: none;
}

.purchase-image {
  width: 100px;
  object-fit: cover;
}

.purchase-discord {
  text-decoration: none;
}

.purchase-image1 {
  width: 100px;
  object-fit: cover;
}

.purchase-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.purchase-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.purchase-heading4 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.purchase-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.purchase-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.purchase-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.purchase-heading5 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.purchase-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.purchase-link1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.purchase-link2 {
  color: rgb(255, 255, 255);
}

.purchase-link3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.purchase-link4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.purchase-socials1 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.purchase-twitter1 {
  text-decoration: none;
}

.purchase-image2 {
  width: 100px;
  object-fit: cover;
}

.purchase-discord1 {
  text-decoration: none;
}

.purchase-image3 {
  width: 100px;
  object-fit: cover;
}

.purchase-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

@media(max-width: 991px) {
  .purchase-desktop-menu {
    display: none;
  }

  .purchase-btn-group {
    display: none;
  }

  .purchase-burger-menu {
    display: flex;
  }

  .purchase-button5 {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: row;
  }

  .purchase-logo1 {
    color: #000000;
  }

  .purchase-caption {
    text-align: center;
  }

  .purchase-heading {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .purchase-heading1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .purchase-caption1 {
    text-align: center;
  }

  .purchase-heading2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .purchase-caption2 {
    text-align: center;
  }

  .purchase-caption3 {
    text-align: center;
  }
}

@media(max-width: 767px) {
  .purchase-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .purchase-content {
    padding-top: var(--dl-space-space-threeunits);
  }

  .purchase-main {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .purchase-header {
    font-size: 36px;
    line-height: 43px;
  }

  .purchase-caption {
    font-size: 16px;
    line-height: 24px;
  }

  .purchase-heading {
    gap: var(--dl-space-space-unit);
  }

  .purchase-heading1 {
    gap: var(--dl-space-space-unit);
  }

  .purchase-caption1 {
    font-size: 16px;
    line-height: 24px;
  }

  .purchase-heading2 {
    gap: var(--dl-space-space-unit);
  }

  .purchase-caption2 {
    font-size: 16px;
    line-height: 24px;
  }

  .purchase-caption3 {
    font-size: 16px;
    line-height: 24px;
  }

  .purchase-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .purchase-main1 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }

  .purchase-heading3 {
    gap: var(--dl-space-space-unit);
  }

  .purchase-logo2 {
    font-size: 20px;
    line-height: 24px;
  }

  .purchase-caption4 {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }

  .purchase-socials {
    display: none;
  }

  .purchase-links {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }

  .purchase-list {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .purchase-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .purchase-socials1 {
    display: flex;
  }
}

@media(max-width: 479px) {
  .purchase-navbar {
    padding: var(--dl-space-space-unit);
  }

  .purchase-mobile-menu {
    padding: 16px;
  }

  .purchase-caption3 {
    width: 479px;
  }
}
</style>
