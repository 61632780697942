<template>
  <div class="homech-container">
    <header data-thq="thq-navbar" class="homech-navbar">
      <span class="homech-logo">aDeal</span>
      <div data-thq="thq-navbar-nav" data-role="Nav" class="homech-desktop-menu">
        <nav data-thq="thq-navbar-nav-links" data-role="Nav" class="homech-nav">
          <a href="#about" class="homech-link button-clean button">關於我們</a>
          <a href="#features" class="homech-link01 button-clean button">
            功能特色
          </a>
          <a href="#benefits" class="homech-link02 button-clean button">
            <span>好處多多</span>
          </a>
          <a href="#roadmap" class="homech-link03 button-clean button">
            發展地圖
          </a>
          <a href="#faq" class="homech-link04 button-clean button">FAQ</a>
        </nav>
      </div>
      <div data-thq="thq-navbar-btn-group" class="homech-btn-group">
        <div class="homech-socials">
          <button class="social button">
            <img alt="image" src="/Icons/twitter.svg" class="homech-image" />
          </button>
          <button class="social button">
            <svg viewBox="0 0 1024 1024" class="homech-icon">
              <path
                d="M698 598h144q12-56 12-86t-12-86h-144q6 42 6 86t-6 86zM622 834q50-16 104-61t82-91h-126q-20 80-60 152zM612 598q6-42 6-86t-6-86h-200q-6 42-6 86t6 86h200zM512 852q56-82 82-170h-164q26 88 82 170zM342 342q24-86 60-152-50 16-105 61t-81 91h126zM216 682q26 46 81 91t105 61q-40-72-60-152h-126zM182 598h144q-6-42-6-86t6-86h-144q-12 56-12 86t12 86zM512 172q-56 82-82 170h164q-26-88-82-170zM808 342q-28-46-82-91t-104-61q36 66 60 152h126zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"
              ></path>
            </svg>
            <img alt="image" src="/Icons/discord.svg" class="homech-image01" />
          </button>
          <button class="social button">
            <router-link to="/">
              <svg viewBox="0 0 1024 1024" class="homech-icon02">
                <path
                  d="M698 598h144q12-56 12-86t-12-86h-144q6 42 6 86t-6 86zM622 834q50-16 104-61t82-91h-126q-20 80-60 152zM612 598q6-42 6-86t-6-86h-200q-6 42-6 86t6 86h200zM512 852q56-82 82-170h-164q26 88 82 170zM342 342q24-86 60-152-50 16-105 61t-81 91h126zM216 682q26 46 81 91t105 61q-40-72-60-152h-126zM182 598h144q-6-42-6-86t6-86h-144q-12 56-12 86t12 86zM512 172q-56 82-82 170h164q-26-88-82-170zM808 342q-28-46-82-91t-104-61q36 66 60 152h126zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"
                ></path>
              </svg>
            </router-link>
          </button>
        </div>
        <button class="button">Sign Open Letter</button>
      </div>
      <div data-thq="thq-burger-menu" class="homech-burger-menu">
        <button class="button homech-button">
          <svg viewBox="0 0 1024 1024" class="homech-icon04">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
            ></path>
          </svg>
        </button>
      </div>
      <div data-thq="thq-mobile-menu" class="homech-mobile-menu">
        <div data-thq="thq-mobile-menu-nav" data-role="Nav" class="homech-nav1">
          <div class="homech-container1">
            <span class="homech-logo1">Character</span>
            <div data-thq="thq-close-menu" class="homech-menu-close">
              <svg viewBox="0 0 1024 1024" class="homech-icon06">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path>
              </svg>
            </div>
          </div>
          <nav
            data-thq="thq-mobile-menu-nav-links"
            data-role="Nav"
            class="homech-nav2"
          >
            <span class="homech-text01">About</span>
            <span class="homech-text02">Features</span>
            <span class="homech-text03">Pricing</span>
            <span class="homech-text04">Team</span>
            <span class="homech-text05">Blog</span>
          </nav>
        </div>
        <div class="homech-icon-group">
          <svg viewBox="0 0 950.8571428571428 1024" class="homech-icon08">
            <path
              d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
            ></path></svg
          ><svg viewBox="0 0 877.7142857142857 1024" class="homech-icon10">
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
            ></path></svg
          ><svg viewBox="0 0 602.2582857142856 1024" class="homech-icon12">
            <path
              d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
            ></path>
          </svg>
        </div>
      </div>
    </header>
    <section class="homech-hero">
      <div class="homech-heading">
        <h1 class="homech-header">讓您的願望清單找到完美的優惠。</h1>
        <p class="homech-caption">
          您的購物計劃，讓廠商來報價，前所未有的優惠配對！
        </p>
      </div>
      <div class="homech-buttons">
        <button class="button">Sign Open Letter</button>
        <button class="homech-learn button-clean button">Learn more</button>
      </div>
    </section>
    <section class="homech-description">
      <img
        alt="image"
        src="/hero-divider-1500w.png"
        class="homech-divider-image"
      />
      <div class="homech-container3">
        <div class="homech-description01">
          <div class="homech-content">
            <p class="homech-paragraph">
              在當今的數位時代，網路廣告常常讓人感覺像是一堆非個人化和不相關的內容。透過
              「aDeal」一個革命性的協議，彌合消費者需求和企業供應產品之間的差距。
            </p>
            <p class="homech-paragraph1">
              aDeal
              的核心是讓消費者能夠策劃管理個人化願望清單，詳細說明他們真正想要的產品或服務。
              另一方面，企業可以存取這些願望清單，從而可以訂製滿足潛在客戶當前需求的產品或服務。
            </p>
          </div>
          <div class="homech-links">
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer noopener"
              class="homech-link05 button-link button"
            >
              <span>Follow us on Twitter</span>
              <img alt="image" src="/Icons/arrow.svg" class="homech-arrow" />
            </a>
            <a
              href="https://discord.com"
              target="_blank"
              rel="noreferrer noopener"
              class="homech-link06 button-link button"
            >
              <span>Governance Forum</span>
              <img alt="image" src="/Icons/arrow.svg" class="homech-arrow1" />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="homech-collection">
      <div class="homech-content01">
        <span id="about" class="homech-caption01">關於我們</span>
        <div class="homech-heading01">
          <h2 class="homech-header01">消費者與供應鏈的雙邊服務</h2>
          <p class="homech-header02">
            aDeal 旨在改變網路廣告格局。
            透過使用虛擬分身和購買計劃將用戶定位在廣告生態系統的核心，確保消費者隱私並增強個人化。
            匿名化身可以保護消費者的身份，而基於關鍵字的動態購買計劃使企業能夠更有效地滿足消費者的真實需求。
          </p>
          <p class="homech-header03">
            我們倡導去中心化。aDeal不是由單一執行長領導，而是由aDeal DAO
            社群的集體智慧和熱情驅動，並由治理代幣aDealv提供支持與集體決策。我們開創了
            aDeal 協議，為線上廣告生態系統開創一個更透明和去中心化的時代。
            讓我們一起共同重新定義線上廣告的未來。”
          </p>
        </div>
      </div>
    </section>
    <section class="homech-project">
      <span id="features" class="homech-caption02">功能特色</span>
      <p class="homech-header04">
        <span>
          使用者驅動的願望清單：使用者建立和管理願望清單，確保他們只看到他們真正想要的產品和服務的廣告。
        </span>
        <br />
        <br />
        <span>
          廠商供應：企業可以列出他們的產品或服務，確保針對潛在客戶的願望清單量身定制精確的廣告活動。
        </span>
        <br />
        <br />
        <span>
          人工智慧增強配對：高度個人化的廣告，與消費者的真實興趣產生共鳴。
        </span>
        <br />
        <br />
        <span>
          信譽系統：使用者可以對廣告/產品進行評分和評論，創造持續改進的環境。
        </span>
        <br />
        <br />
        <span>
          去中心化模型：去中心化的治理模型，由社群控制平台的發展，而單一企業所控制，實現社群治理。
        </span>
      </p>
    </section>
    <section class="homech-cards">
      <span id="benefits" class="homech-caption03">好處多多</span>
      <div class="homech-row">
        <div class="homech-card">
          <div class="homech-avatar">
            <img alt="image" src="/Avatars/avatar.svg" class="homech-avatar1" />
          </div>
          <div class="homech-main">
            <div class="homech-content02">
              <h2 class="homech-header05">個人化體驗</h2>
              <p class="homech-description02">
                根據您策劃的願望清單，僅接收您真正想要的產品和服務的廣告。
              </p>
            </div>
          </div>
        </div>
        <div class="homech-card1">
          <div class="homech-avatar2">
            <img
              alt="image"
              src="/Avatars/default-avatar.svg"
              class="homech-avatar3"
            />
          </div>
          <div class="homech-main1">
            <div class="homech-content03">
              <h2 class="homech-header06">隱私保護</h2>
              <p class="homech-description03">
                透過匿名分身技術，所有的願望清單都與真實的個人身份隔離，確保消費者隱私。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="homech-card2">
        <div class="homech-avatar4">
          <img
            alt="image"
            src="/Avatars/light-avatar.svg"
            class="homech-avatar5"
          />
        </div>
        <div class="homech-row1">
          <div class="homech-main2">
            <div class="homech-content04">
              <h2 class="homech-header07">真正的精準行銷</h2>
              <p class="homech-description04">
                直接對客戶報價，提供潛在客戶一對一行銷。不再浪費廣告成本。
              </p>
            </div>
          </div>
          <img alt="image" src="/group%202262.svg" class="homech-image02" />
        </div>
      </div>
    </section>
    <section class="homech-roadmap">
      <div class="homech-heading02">
        <h2 id="roadmap" class="homech-header08">發展地圖</h2>
      </div>
      <div class="homech-list">
        <div class="homech-step">
          <span class="homech-caption04">December 2023</span>
          <div class="homech-heading03">
            <h2 class="homech-header09">社群募集</h2>
            <p class="homech-header10">
              <span>
                社群建構： 在 aDeal 官方網站上發佈公開信，允許用戶使用 Metamask
                進行簽名。
              </span>
              <br />
              <span>代幣空投：以空投形式向早期支持的採用者分發治理代幣。</span>
            </p>
          </div>
        </div>
        <div class="homech-step1">
          <span class="homech-caption05">January 2024</span>
          <div class="homech-heading04">
            <h2 class="homech-header11">造市者合作</h2>
            <button class="homech-button1 button">View on DEX</button>
            <p class="homech-header12">
              代幣在 DEX
              上架：募集造市商，提供代幣的流動性，在著名的去中心化交易所 (DEX)
              上架，為早期採用者與客戶提供廣告點數買賣途徑。
            </p>
          </div>
        </div>
        <div class="homech-step2">
          <span class="homech-caption06">Q1 2024</span>
          <div class="homech-heading05">
            <h2 class="homech-header13">發布aDeal協議</h2>
            <p class="homech-header14">
              <span>
                aDeal Avatar：推出使用者ID 建立機制，這是一個基於加密的匿名ID
                系統，以保護使用者身分。
              </span>
              <br />
              <span>
                aDeal 前端：啟動一個基本的基於Web
                的介面，使用戶能夠註冊、使用關鍵字管理願望清單，並接收來自企業的客製化推播廣告。
              </span>
              <br />
              <span>
                企業後端：推出專為企業量身定制的基於網路的後端入口網站。
                此入口網站將促進企業資料建立、訂閱用戶資料並協助企業將推播廣告廣播到符合的願望清單。
              </span>
            </p>
          </div>
        </div>
        <div class="homech-step3">
          <span class="homech-caption07">Q2 2024</span>
          <div class="homech-heading06">
            <h2 class="homech-header15">aDeal AI 整合</h2>
            <p class="homech-header16">
              人工智慧驅動的匹配引擎：開發和整合人工智慧增強型引擎，旨在使用戶願望清單與相應的業務產品保持一致。
              這一階段也將引進OpenAI，增強平台的匹配能力。
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="homech-join-us">
      <div class="homech-content05">
        <div class="homech-main3">
          <div class="homech-heading07">
            <h2 class="homech-header17">現在開始許願</h2>
            <p class="homech-caption08">專為您量身訂製的個人化優惠！</p>
          </div>
          <button class="homech-view2 button">Sign Open Letter</button>
        </div>
        <img alt="image" src="/group%202273.svg" class="homech-image03" />
      </div>
    </section>
    <section class="homech-faq">
      <h2 id="faq" class="homech-header18">FAQ</h2>
      <div class="homech-accordion">
        <div data-role="accordion-container" class="homech-element accordion">
          <div class="homech-content06">
            <span class="homech-header19">aDeal 是免費的嗎?</span>
            <span data-role="accordion-content" class="homech-description05">
              是的，aDeal 對用戶免費。 aDeal 協議向企業收取廣告服務費用。
            </span>
          </div>
          <div class="homech-icon-container">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="homech-icon14"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="homech-icon16"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
        <div data-role="accordion-container" class="homech-element1 accordion">
          <div class="homech-content07">
            <span class="homech-header20">何在aDeal上設定我的願望清單？</span>
            <span data-role="accordion-content" class="homech-description06">
              設定願望清單非常簡單！ 只需在 aDeal
              協議上註冊，建立匿名頭像，然後開始添加您感興趣的關鍵字或項目。然後，企業將能夠根據您的偏好向您發送量身定制的優惠。
            </span>
          </div>
          <div class="homech-icon-container1">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="homech-icon18"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="homech-icon20"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
        <div data-role="accordion-container" class="homech-element2 accordion">
          <div class="homech-content08">
            <span class="homech-header21">
              我是一個企業。 我如何從aDeal協議中受益？
            </span>
            <span data-role="accordion-content" class="homech-description07">
              aDeal 為企業提供直接了解消費者真正需求的途徑。
              透過訂閱用戶資料並推送針對個人願望清單客製化的廣告，企業可以實現更高的參與率、更好的投資報酬率並培養潛在客戶的信任關係。
            </span>
          </div>
          <div class="homech-icon-container2">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="homech-icon22"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="homech-icon24"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
        <div data-role="accordion-container" class="homech-element3 accordion">
          <div class="homech-content09">
            <span class="homech-header22">什麼是 DEAL 代幣?</span>
            <span data-role="accordion-content" class="homech-description08">
              <span>aDeal 實用代幣(DEAL) 主要功能：</span>
              <span>用於aDeal 生態系統內的日常交易。</span>
              <br />
              <br />
              <span>實用方面：</span>
              <span>廣告積分，企業使用Deal Token 來支付一對一廣告費用。</span>
              <br />
              <br />
              <span>
                用戶獎勵：用戶透過建立願望清單等操作賺取DealToken
                ，提供回饋等。這些代幣可以兌換折扣、服務或在合作夥伴企業使用。
              </span>
            </span>
          </div>
          <div class="homech-icon-container3">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="homech-icon26"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="homech-icon28"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
        <div data-role="accordion-container" class="homech-element4 accordion">
          <div class="homech-content10">
            <span class="homech-header23">什麼是DEALv 代幣?</span>
            <span data-role="accordion-content" class="homech-description09">
              <span>
                aDeal 治理代幣(DEALv) 主要功能：代表aDeal 平台決策過程中的權益。
              </span>
              <br />
              <br />
              <span>
                治理面向：平台投票。代幣持有者可以對平台變更、功能開發和其他重要決策提出建議和投票。影響力權益：權益GOV
                代幣用於增加投票權重或對平台的影響力。
              </span>
              <br />
              <br />
              <span>
                aDeal是一個由社群所治理的一對一廣告協議，而非單一企業控制。DEALv就是治理代幣。
              </span>
              <br />
            </span>
          </div>
          <div class="homech-icon-container4">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="homech-icon30"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="homech-icon32"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </section>
    <section class="homech-get-yours">
      <div class="homech-row2">
        <div class="homech-column">
          <div class="homech-card3">
            <img
              alt="image"
              src="/Characters/character-9.svg"
              class="homech-image04"
            />
          </div>
        </div>
        <div class="homech-column1">
          <div class="homech-card4">
            <img
              alt="image"
              src="/Characters/character-10.svg"
              class="homech-image05"
            />
          </div>
        </div>
      </div>
      <div class="homech-column2">
        <div class="homech-card5">
          <div class="homech-content11">
            <h2 class="homech-header24">加入我們</h2>
            <p class="homech-description10">
              加入 aDeal 合作夥伴計劃，成為不斷發展的社區中不可或缺的一部分。
              作為合作夥伴，您擁有獨特的機會為aDeal的願景做出貢獻，推動當地參與，並從廣告網絡中受益。
            </p>
          </div>
          <button class="homech-button2 button">Contact Us</button>
        </div>
      </div>
    </section>
    <footer class="homech-footer">
      <div class="homech-main4">
        <div class="homech-branding">
          <div class="homech-heading08">
            <h2 class="homech-logo2">aDeal</h2>
            <p class="homech-caption09">
              我們的願景是重塑線上廣告模式，使其更加公平、有效率、以用戶為中心，確保每一筆交易確實都是一筆划算的交易。
            </p>
          </div>
          <div class="homech-socials1">
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer noopener"
              class="homech-twitter1 social button"
            >
              <img alt="image" src="/Icons/twitter.svg" class="homech-image06" />
            </a>
            <a
              href="https://discord.com"
              target="_blank"
              rel="noreferrer noopener"
              class="homech-discord1 social button"
            >
              <img alt="image" src="/Icons/discord.svg" class="homech-image07" />
            </a>
          </div>
        </div>
        <div class="homech-links1">
          <div class="homech-list1">
            <h3 class="homech-heading09">Site</h3>
            <div class="homech-items">
              <button class="homech-link07 button-clean button">About</button>
              <button class="homech-link08 button-clean button">Features</button>
              <button class="homech-link09 button-clean button">Benefit</button>
              <button class="homech-link10 button-clean button">
                <span>
                  <span>Roadmap</span>
                  <br />
                </span>
              </button>
              <button class="homech-link11 button-clean button">
                <span>
                  <span>FAQ</span>
                  <br />
                </span>
              </button>
            </div>
          </div>
          <div class="homech-list2">
            <h3 class="homech-heading10">Company</h3>
            <div class="homech-items1">
              <button class="homech-link12 button-clean button">Team</button>
              <button class="homech-link13 button-clean button">Blog</button>
              <button class="homech-link14 button-clean button">Terms</button>
              <button class="homech-link15 button-clean button">Privacy</button>
            </div>
          </div>
        </div>
        <div class="homech-socials2">
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noreferrer noopener"
            class="homech-twitter2 social button"
          >
            <img alt="image" src="/Icons/twitter.svg" class="homech-image08" />
          </a>
          <a
            href="https://discord.com"
            target="_blank"
            rel="noreferrer noopener"
            class="homech-discord2 social button"
          >
            <img alt="image" src="/Icons/discord.svg" class="homech-image09" />
          </a>
        </div>
      </div>
      <span class="homech-copyright">© 2023 aDeal. All Rights Reserved.</span>
    </footer>
    <div>
      <div class="homech-container5">
        <DangerousHTML
          html="<script>
   /*
    Accordion - Code Embed
    */
    const accordionContainers = document.querySelectorAll('[data-role='accordion-container']'); // All accordion containers
    const accordionContents = document.querySelectorAll('[data-role='accordion-content']'); // All accordion content
    const accordionIconsClosed = document.querySelectorAll('[data-role='accordion-icon-closed']'); // All accordion closed icons
    const accordionIconsOpen = document.querySelectorAll('[data-role='accordion-icon-open']'); // All accordion open icons
  
    accordionContents.forEach((accordionContent) => {
        accordionContent.style.display = 'none'; //Hides all accordion contents
    });
  
    accordionIconsClosed.forEach((icon) => {
      icon.style.display = 'flex'
    })
  
    accordionIconsOpen.forEach((icon) => {
      icon.style.display = 'none'
    })
  
    accordionContainers.forEach((accordionContainer, index) => {
        accordionContainer.addEventListener('click', () => {
            if (accordionContents[index].style.display === 'flex') {
                // If the accordion is already open, close it
                accordionContents[index].style.display = 'none';
                accordionIconsClosed[index].style.display = 'flex';
                accordionIconsOpen[index].style.display = 'none'
            } else {
                // If the accordion is closed, open it
                accordionContents.forEach((accordionContent) => {
                    accordionContent.style.display = 'none'; //Hides all accordion contents
                });
  
                accordionIconsClosed.forEach((accordionIcon) => {
                    accordionIcon.style.display = 'flex'; // Resets all icon transforms to 0deg (default)
                });
  
                accordionIconsOpen.forEach((accordionIcon) => {
                  accordionIcon.style.display = 'none';
                })
                
                accordionContents[index].style.display = 'flex'; // Shows accordion content
                accordionIconsClosed[index].style.display = 'none'; // Rotates accordion icon 180deg
                accordionIconsOpen[index].style.display = 'flex';
            }
        });
    });
  </script>
  "
        ></DangerousHTML>
      </div>
    </div>
    <svg viewBox="0 0 1024 1024" class="homech-icon34">
      <path
        d="M698 598h144q12-56 12-86t-12-86h-144q6 42 6 86t-6 86zM622 834q50-16 104-61t82-91h-126q-20 80-60 152zM612 598q6-42 6-86t-6-86h-200q-6 42-6 86t6 86h200zM512 852q56-82 82-170h-164q26 88 82 170zM342 342q24-86 60-152-50 16-105 61t-81 91h126zM216 682q26 46 81 91t105 61q-40-72-60-152h-126zM182 598h144q-6-42-6-86t6-86h-144q-12 56-12 86t12 86zM512 172q-56 82-82 170h164q-26-88-82-170zM808 342q-28-46-82-91t-104-61q36 66 60 152h126zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"
      ></path>
    </svg>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

export default {
  name: 'Homech',
  components: {
    DangerousHTML,
  },
  metaInfo: {
    title: 'Homech - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'aDeal network',
      },
      {
        property: 'og:description',
        content: 'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
      {
        property: 'og:image',
        content: 'https://adeal.network/hero-divider-1500w.png',
      },
      {
        property: 'og:image:alt',
        content: 'aDeal network',
      },
      {
        property: 'og:image:type',
        content: 'image/png',
      },
      {
        property: 'og:url',
        content: 'https://adeal.network/',
      },
      {
        property: 'og:site_name',
        content: 'aDeal network',
      },
    ],
  },
}
</script>

<style scoped>
.homech-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.homech-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.homech-logo {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.homech-desktop-menu {
  display: flex;
}
.homech-nav {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.homech-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.homech-link01 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.homech-link02 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.homech-link03 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.homech-link04 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.homech-btn-group {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.homech-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.homech-image {
  width: 100px;
  object-fit: cover;
}
.homech-icon {
  width: 24px;
  height: 24px;
}
.homech-image01 {
  width: 100px;
  object-fit: cover;
}
.homech-icon02 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.homech-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.homech-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.homech-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.homech-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.homech-logo1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.homech-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homech-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.homech-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-text01 {
  margin-bottom: var(--dl-space-space-unit);
}
.homech-text02 {
  margin-bottom: var(--dl-space-space-unit);
}
.homech-text03 {
  margin-bottom: var(--dl-space-space-unit);
}
.homech-text04 {
  margin-bottom: var(--dl-space-space-unit);
}
.homech-text05 {
  margin-bottom: var(--dl-space-space-unit);
}
.homech-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.homech-login {
  margin-right: var(--dl-space-space-twounits);
}
.homech-icon-group {
  display: flex;
}
.homech-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.homech-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.homech-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.homech-hero {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.homech-heading {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.homech-header {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}
.homech-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  text-align: center;
  line-height: 36px;
}
.homech-buttons {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.homech-learn {
  color: rgb(255, 255, 255);
}
.homech-description {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.homech-divider-image {
  width: 100%;
  object-fit: cover;
}
.homech-container3 {
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-end;
  flex-direction: column;
}
.homech-description01 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.homech-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-paragraph {
  color: rgb(255, 255, 255);
  font-size: 40px;
  line-height: 60px;
}
.homech-paragraph1 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  line-height: 60px;
}
.homech-links {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.homech-link05 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  border-top-width: 0px;
}
.homech-arrow {
  width: 25px;
  object-fit: cover;
}
.homech-link06 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  border-top-width: 0px;
}
.homech-arrow1 {
  width: 25px;
  object-fit: cover;
}
.homech-collection {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.homech-content01 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-caption01 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.homech-heading01 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header01 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}
.homech-header02 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.homech-header03 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.homech-project {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.homech-caption02 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.homech-header04 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.homech-cards {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.homech-caption03 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.homech-row {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.homech-card {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #000000;
}
.homech-avatar {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: #FDEA6B;
}
.homech-avatar1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homech-main {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-content02 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header05 {
  color: rgb(255, 255, 255);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.homech-description02 {
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}
.homech-card1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #FFCF77;
}
.homech-avatar2 {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #EC7495;
}
.homech-avatar3 {
  width: 28px;
  object-fit: cover;
}
.homech-main1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-content03 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header06 {
  color: rgb(0, 0, 0);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.homech-description03 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}
.homech-card2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #8FA7DF;
}
.homech-avatar4 {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}
.homech-avatar5 {
  width: 22px;
  object-fit: cover;
}
.homech-row1 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.homech-main2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-content04 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header07 {
  color: rgb(0, 0, 0);
  width: 241px;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.homech-description04 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}
.homech-image02 {
  width: 100%;
  object-fit: cover;
}
.homech-roadmap {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.homech-heading02 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: center;
  flex-direction: column;
}
.homech-header08 {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  font-weight: 500;
  line-height: 108px;
}
.homech-list {
  gap: 106px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  border-left-width: 1px;
}
.homech-step {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-caption04 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.homech-heading03 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header09 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}
.homech-header10 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.homech-step1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-caption05 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.homech-heading04 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header11 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}
.homech-button1 {
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.homech-header12 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.homech-step2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-caption06 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.homech-heading05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header13 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}
.homech-header14 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.homech-step3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-caption07 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.homech-heading06 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header15 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}
.homech-header16 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.homech-join-us {
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.homech-content05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: #FFCF77;
}
.homech-main3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.homech-heading07 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.homech-header17 {
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}
.homech-caption08 {
  font-size: 24px;
  max-width: 800px;
  line-height: 36px;
}
.homech-view2 {
  background-color: rgb(157, 218, 219);
}
.homech-image03 {
  width: 100%;
  object-fit: cover;
}
.homech-faq {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.homech-header18 {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}
.homech-accordion {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
}
.homech-element {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
  border-top-width: 1px;
}
.homech-content06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header19 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.homech-description05 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.homech-icon-container {
  padding-top: var(--dl-space-space-halfunit);
}
.homech-icon14 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.homech-icon16 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.homech-element1 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}
.homech-content07 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header20 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.homech-description06 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.homech-icon-container1 {
  padding-top: var(--dl-space-space-halfunit);
}
.homech-icon18 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.homech-icon20 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.homech-element2 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}
.homech-content08 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header21 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.homech-description07 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.homech-icon-container2 {
  padding-top: var(--dl-space-space-halfunit);
}
.homech-icon22 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.homech-icon24 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.homech-element3 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}
.homech-content09 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header22 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.homech-description08 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.homech-icon-container3 {
  padding-top: var(--dl-space-space-halfunit);
}
.homech-icon26 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.homech-icon28 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.homech-element4 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}
.homech-content10 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header23 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.homech-description09 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.homech-icon-container4 {
  padding-top: var(--dl-space-space-halfunit);
}
.homech-icon30 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.homech-icon32 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.homech-get-yours {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 555px;
  display: flex;
  max-width: 1440px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.homech-row2 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  flex-direction: row;
}
.homech-column {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.homech-card3 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
  background-color: #FFCF77;
}
.homech-image04 {
  width: 240px;
  object-fit: cover;
}
.homech-column1 {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.homech-card4 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.homech-image05 {
  width: 240px;
  object-fit: cover;
}
.homech-column2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.homech-card5 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  flex-direction: column;
  background-color: #8FA7DF;
}
.homech-content11 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-header24 {
  color: rgb(0, 0, 0);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.homech-description10 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 18px;
  max-width: 500px;
  line-height: 27px;
}
.homech-button2 {
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.homech-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.homech-main4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.homech-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.homech-heading08 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-logo2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.homech-caption09 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.homech-socials1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.homech-twitter1 {
  text-decoration: none;
}
.homech-image06 {
  width: 100px;
  object-fit: cover;
}
.homech-discord1 {
  text-decoration: none;
}
.homech-image07 {
  width: 100px;
  object-fit: cover;
}
.homech-links1 {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.homech-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-heading09 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.homech-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-link07 {
  color: rgb(255, 255, 255);
}
.homech-link08 {
  color: rgb(255, 255, 255);
}
.homech-link09 {
  color: rgb(255, 255, 255);
}
.homech-link10 {
  color: rgb(255, 255, 255);
}
.homech-link11 {
  color: rgb(255, 255, 255);
}
.homech-list2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-heading10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.homech-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homech-link12 {
  color: rgb(255, 255, 255);
}
.homech-link13 {
  color: rgb(255, 255, 255);
}
.homech-link14 {
  color: rgb(255, 255, 255);
}
.homech-link15 {
  color: rgb(255, 255, 255);
}
.homech-socials2 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.homech-twitter2 {
  text-decoration: none;
}
.homech-image08 {
  width: 100px;
  object-fit: cover;
}
.homech-discord2 {
  text-decoration: none;
}
.homech-image09 {
  width: 100px;
  object-fit: cover;
}
.homech-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
.homech-container5 {
  display: contents;
}
.homech-icon34 {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .homech-desktop-menu {
    display: none;
  }
  .homech-btn-group {
    display: none;
  }
  .homech-burger-menu {
    display: flex;
  }
  .homech-button {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .homech-logo1 {
    color: #000000;
  }
  .homech-project {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .homech-row {
    flex-direction: column;
  }
  .homech-card {
    width: 100%;
  }
  .homech-card1 {
    width: 100%;
  }
  .homech-row1 {
    flex-direction: column;
  }
  .homech-heading07 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .homech-caption08 {
    text-align: center;
  }
  .homech-get-yours {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .homech-row2 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    height: 555px;
    align-items: center;
    justify-content: center;
  }
  .homech-column {
    flex: 1;
    align-items: flex-start;
  }
  .homech-card3 {
    width: 100%;
    aspect-ratio: 0.85;
    justify-content: center;
  }
  .homech-column1 {
    flex: 1;
    align-items: flex-end;
  }
  .homech-card4 {
    width: 100%;
    aspect-ratio: 0.85;
  }
  .homech-column2 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .homech-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homech-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homech-heading {
    gap: var(--dl-space-space-unit);
  }
  .homech-header {
    font-size: 36px;
    max-width: 70%;
    line-height: 43px;
  }
  .homech-caption {
    font-size: 16px;
    line-height: 24px;
  }
  .homech-description {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }
  .homech-description01 {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .homech-content {
    gap: var(--dl-space-space-unit);
  }
  .homech-paragraph {
    font-size: 20px;
    line-height: 30px;
  }
  .homech-paragraph1 {
    font-size: 20px;
    line-height: 30px;
  }
  .homech-collection {
    gap: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .homech-content01 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .homech-heading01 {
    gap: var(--dl-space-space-unit);
  }
  .homech-header01 {
    font-size: 32px;
    letter-spacing: 0;
  }
  .homech-header02 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-header03 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-project {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .homech-header04 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-cards {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .homech-card {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .homech-main {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .homech-header05 {
    font-size: 24px;
  }
  .homech-description02 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-card1 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .homech-main1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .homech-header06 {
    font-size: 24px;
  }
  .homech-description03 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-card2 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .homech-row1 {
    gap: var(--dl-space-space-twounits);
  }
  .homech-main2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .homech-header07 {
    font-size: 24px;
  }
  .homech-description04 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-roadmap {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .homech-heading02 {
    gap: var(--dl-space-space-unit);
  }
  .homech-header08 {
    font-size: 36px;
    line-height: 43px;
  }
  .homech-list {
    gap: var(--dl-space-space-threeunits);
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .homech-heading03 {
    gap: var(--dl-space-space-unit);
  }
  .homech-header09 {
    font-size: 32px;
  }
  .homech-header10 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-heading04 {
    gap: var(--dl-space-space-unit);
  }
  .homech-header11 {
    font-size: 32px;
  }
  .homech-button1 {
    margin-top: 0px;
  }
  .homech-header12 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-heading05 {
    gap: var(--dl-space-space-unit);
  }
  .homech-header13 {
    font-size: 32px;
  }
  .homech-header14 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-heading06 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .homech-header15 {
    font-size: 32px;
  }
  .homech-header16 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-join-us {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .homech-content05 {
    padding-top: var(--dl-space-space-threeunits);
  }
  .homech-main3 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .homech-heading07 {
    gap: var(--dl-space-space-unit);
  }
  .homech-header17 {
    font-size: 36px;
    line-height: 43px;
  }
  .homech-caption08 {
    font-size: 16px;
    line-height: 24px;
  }
  .homech-image03 {
    width: 150%;
  }
  .homech-faq {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .homech-header18 {
    font-size: 36px;
    line-height: 43px;
  }
  .homech-element {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .homech-header19 {
    font-size: 16px;
  }
  .homech-description05 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-element1 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .homech-header20 {
    font-size: 16px;
  }
  .homech-description06 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-element2 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .homech-header21 {
    font-size: 16px;
  }
  .homech-description07 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-element3 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .homech-header22 {
    font-size: 16px;
  }
  .homech-description08 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-element4 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .homech-header23 {
    font-size: 16px;
  }
  .homech-description09 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-get-yours {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .homech-row2 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: 555px;
  }
  .homech-card3 {
    padding: var(--dl-space-space-unit);
  }
  .homech-image04 {
    width: 100%;
  }
  .homech-card4 {
    padding: var(--dl-space-space-unit);
  }
  .homech-image05 {
    width: 100%;
  }
  .homech-card5 {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .homech-content11 {
    align-items: center;
  }
  .homech-header24 {
    font-size: 32px;
    text-align: center;
  }
  .homech-description10 {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
  }
  .homech-button2 {
    margin-top: 0px;
  }
  .homech-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .homech-main4 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .homech-heading08 {
    gap: var(--dl-space-space-unit);
  }
  .homech-logo2 {
    font-size: 20px;
    line-height: 24px;
  }
  .homech-caption09 {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }
  .homech-socials1 {
    display: none;
  }
  .homech-links1 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }
  .homech-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .homech-list2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .homech-socials2 {
    display: flex;
  }
}
@media(max-width: 479px) {
  .homech-navbar {
    padding: var(--dl-space-space-unit);
  }
  .homech-mobile-menu {
    padding: 16px;
  }
  .homech-image03 {
    width: 200%;
  }
  .homech-header19 {
    font-size: 16px;
  }
  .homech-description05 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-header20 {
    font-size: 16px;
  }
  .homech-description06 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-header21 {
    font-size: 16px;
  }
  .homech-description07 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-header22 {
    font-size: 16px;
  }
  .homech-description08 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-header23 {
    font-size: 16px;
  }
  .homech-description09 {
    font-size: 14px;
    line-height: 21px;
  }
  .homech-row2 {
    height: 350px;
  }
}
</style>
