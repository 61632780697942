<template>
  <div class="create-wishlist-container">
    <section class="create-wishlist-cards">
      <h2 class="create-wishlist-header">Create your wishlist</h2>
      <p class="create-wishlist-description">
        Create your personalized wishlist. Enter any item you desire. You can
        always come back to modify your wishlist items at any time.
        <span v-html="rawc3ov"></span>
      </p>
      <form
        target="Smartphones or tech gadgets Travel or vacation experiences Books or e-readers Fitness equipment or wellness products Fashion items or accessories"
        class="create-wishlist-form"
      >
        <textarea
          placeholder="Smartphones or tech gadgets, Travel or vacation experiences, Books or e-readers, Fitness equipment or wellness products, Fashion items or accessories"
          class="create-wishlist-textarea textarea"
        ></textarea>
      </form>
      <router-link to="/create-wishlist2" class="create-wishlist-navlink button">
        Create
      </router-link>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CreateWishlist',
  data() {
    return {
      rawc3ov: ' ',
    }
  },
  metaInfo: {
    title: 'CreateWishlist - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'CreateWishlist - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
}
</script>

<style scoped>
.create-wishlist-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.create-wishlist-cards {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.create-wishlist-header {
  color: rgb(255, 255, 255);
  width: 523px;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.create-wishlist-description {
  color: var(--dl-color-gray-white);
  width: 437px;
  height: 93px;
  font-size: 18px;
  max-width: auto;
  line-height: 27px;
  margin-right: 1px;
}
.create-wishlist-form {
  width: 510px;
  height: 167px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-wishlist-textarea {
  width: 440px;
  height: 157px;
}
.create-wishlist-navlink {
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
@media(max-width: 767px) {
  .create-wishlist-cards {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .create-wishlist-header {
    font-size: 24px;
  }
  .create-wishlist-description {
    font-size: 14px;
    line-height: 21px;
  }
  .create-wishlist-navlink {
    margin-top: 0px;
  }
}
@media(max-width: 479px) {
  .create-wishlist-header {
    width: 426px;
    height: 30px;
  }
  .create-wishlist-description {
    width: 421px;
  }
}
</style>
