export {DidApi};

window.DidApi= DidApi;

var DidApi = {
    host :'',
    init : function(host) {
        console.log("window.DidApi", this.host)
        this.host= host;
        if(!this.host) this.host= "";
    },
    get_version : function(callback) {
        console.log("host", this.host);

        $.ajax({
            type: 'GET',
            url: this.host + '/api/version',
            cache: false,
            success: function(response) {
                callback(null, response);
            },
            error: function(xhr, textStatus, thrownError) {
                var err = {
                    functionName: 'get_version',
                    xhr: xhr,
                    textStatus: textStatus,
                    thrownError: thrownError
                }
                callback(err , xhr.responseText);
            }                
        });
    },
    get_jwt_signed_token : function(callback) {
        $.ajax({
            type: 'GET',
            url: this.host + '/api/jwt/token',
            cache: false,
            success: function(response) {
                if(response.error==0) callback(null, response.data)
                else callback(response, response.msg);
            },
            error: function(xhr, textStatus, thrownError) {
                var err = {
                    functionName: 'get_jwt_signed_token',
                    xhr: xhr,
                    textStatus: textStatus,
                    thrownError: thrownError
                }
                callback(err , xhr.responseText);
            }                
        });
    },
    get_jwt_require : function(jwt, callback) {
        $.ajax({
            type: 'GET',
            url: this.host + '/api/jwt/require',
            headers: {"Authorization": "Bearer "+jwt},
            cache: false,
            success: function(response) {
                if(response.error==0) callback(null, response.data)
                else callback(response, response.msg);
            },
            error: function(xhr, textStatus, thrownError) {
                var err = {
                    functionName: 'get_jwt_require',
                    xhr: xhr,
                    textStatus: textStatus,
                    thrownError: thrownError
                }
                callback(err , xhr.responseText);
            }                
        });
    },
    did_register : function(address, twitter_link, fb_link, kyc_form, sign_message, callback) {
        let post_data = {
            'address': address,
            'twitter_link': twitter_link,
            'fb_link': fb_link,
            'kyc_form':kyc_form,
            'sign_message': sign_message,
        };

        $.ajax({
            type: 'POST',
            url: this.host + '/api/did/register',
            data: JSON.stringify(post_data),
            dataType: "json",
            contentType: 'application/json',
            cache: false,
            success: function(response) {
                if(response.error==0) callback(null, response.data)
                else callback(response, response.msg);
            },
            error: function(xhr, textStatus, thrownError) {
                var err = {
                    functionName: 'did_register',
                    xhr: xhr,
                    textStatus: textStatus,
                    thrownError: thrownError
                }
                callback(err , xhr.responseText);
            }
        });
    },
    did_get_user : function(jwt) {
        let error= 0;
        let data= null;
        $.ajax({
            type: 'GET',
            url: this.host + '/api/did/user',
            headers: {"Authorization": "Bearer "+jwt},
            dataType: "json",
            contentType: 'application/json',
            cache: false,
            async: false,   //wait
            success: function(response) {
                error= response.error;
                if(response.error==0) data= response.data;
                else data= response.msg;
            },
            error: function(xhr, textStatus, thrownError) {
            }
        });
        return [error, data];
    },
    get_users : function(callback) {
        $.ajax({
            type: 'GET',
            url: this.host + '/api/users',
            cache: false,
            success: function(response) {
                if(response.error==0) callback(null, response.data)
                else callback(response, response.msg);
            },
            error: function(xhr, textStatus, thrownError) {
                var err = {
                    functionName: 'get_users',
                    xhr: xhr,
                    textStatus: textStatus,
                    thrownError: thrownError
                }
                callback(err , xhr.responseText);
            }                
        });
    },
    


}
