import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import WhitePaper from './views/white-paper'
import Terms from './views/terms'
import Privacy from './views/privacy'
import CreateWishlist from './views/create-wishlist'
import Team from './views/team'
import SignOpenLetter2 from './views/sign-open-letter2'
import VerifyYourSignature from './views/verify-your-signature'
import CreateWishlist2 from './views/create-wishlist2'
import Home from './views/home'
import Homech from './views/homech'
import SignOpenLetter from './views/sign-open-letter'
import Roadmap from './views/roadmap'
import BackersProgram from './views/backers-program'
import Portfolio from './views/portfolio'
import TermsConditions from './views/terms-conditions'
import Purchase from './views/purchase'
import NotFound from './views/not-found'
import './style.css'

//external assets
// import './assets/css/style.css'
// import './assets/css/vendor.bundle.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'WhitePaper',
      path: '/white-paper',
      component: WhitePaper,
    },
    {
      name: 'Terms',
      path: '/terms',
      component: Terms,
    },
    {
      name: 'Privacy',
      path: '/privacy',
      component: Privacy,
    },
    {
      name: 'CreateWishlist',
      path: '/create-wishlist',
      component: CreateWishlist,
    },
    {
      name: 'team',
      path: '/team',
      component: Team,
    },
    {
      name: 'SignOpenLetter2',
      path: '/sign-open-letter2',
      component: SignOpenLetter2,
    },
    {
      name: 'VerifyYourSignature',
      path: '/verify-your-signature',
      component: VerifyYourSignature,
    },
    {
      name: 'CreateWishlist2',
      path: '/create-wishlist2',
      component: CreateWishlist2,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'Homech',
      path: '/homech',
      component: Homech,
    },
    {
      name: 'SignOpenLetter',
      path: '/sign-open-letter',
      component: SignOpenLetter,
    },
    {
      name: 'Roadmap',
      path: '/roadmap',
      component: Roadmap,
    },
    {
      name: 'BackersProgram',
      path: '/backers-program',
      component: BackersProgram,
    },
    {
      name: 'Portfolio',
      path: '/portfolio',
      component: Portfolio,
    },
    {
      name: 'TermsConditions',
      path: '/terms-conditions',
      component: TermsConditions,
    },
    {
      name: 'Purchase',
      path: '/purchase',
      component: Purchase,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
  ],
})
