<template>
  <div class="home-container">
    <aDealHeader></aDealHeader>

    <section class="home-hero">
      <div class="home-heading">
        <h1 class="home-header">Let your wishlist finds its perfect offer.</h1>
        <p class="home-caption">
          A personalized deal and plan based purchase matchmaking
        </p>
      </div>
      <div class="home-buttons">
        <button class="button" @click="goSignOpenLetter">Sign Open Letter</button>
      </div>
    </section>
    <section class="home-description">
      <img alt="image" src="/hero-divider-1500w.png" class="home-divider-image" />
      <div class="home-container3">
        <div class="home-description01">
          <div class="home-content">
            <p class="home-paragraph">
              In today&apos;s digital era, online advertising often feels like a
              barrage of impersonal and irrelevant content. Enter
              &quot;aDeal&quot; – a revolutionary platform designed to bridge the
              gap between consumers&apos; desires and businesses&apos; offerings.
            </p>
            <p class="home-paragraph1">
              At its core, aDeal empowers consumers to curate personalized
              wishlists, detailing products or services they genuinely desire. On
              the flip side, businesses gain access to these wishlists, allowing
              them to craft offers that directly cater to potential
              customers&apos; current wants.
            </p>
          </div>
          <div class="home-links">
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link05 button-link button"
            >
              <span>Follow us on Twitter</span>
              <img alt="image" src="/Icons/arrow.svg" class="home-arrow" />
            </a>
            <a
              href="https://discord.com"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link06 button-link button"
            >
              <span>Governance Forum</span>
              <img alt="image" src="/Icons/arrow.svg" class="home-arrow1" />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="home-collection">
      <div class="home-content01">
        <span id="about" class="home-caption01">about</span>
        <div class="home-heading01">
          <h2 class="home-header01">Serving both consumers and businesses</h2>
          <p class="home-header02">
            aDeal is set to transform the online advertising landscape. By
            positioning users at the heart of the advertising ecosystem through
            the use of avatars and purchase plans, it ensures consumer privacy and
            enhances personalization. Anonymous avatars shield consumers&apos;
            identities, while dynamic keyword-based purchase plans enable
            businesses to engage more effectively with genuine consumer needs.
          </p>
          <p class="home-header03">
            At aDeal, we champion decentralization. Rather than being led by a
            singular CEO, we&apos;re driven by the collective intelligence and
            passion of the aDeal DAO community, powered by our governance token,
            aDealv. Our mission isn&apos;t to birth another tech behemoth to
            dominate the ads market. Instead, we&apos;ve pioneered the aDeal
            network to usher in a more transparent and decentralized era for the
            online advertising ecosystem. Together, we&apos;re redefining the
            future of online advertising.
          </p>
        </div>
      </div>
    </section>
    <section class="home-project">
      <span id="features" class="home-caption02">features</span>
      <p class="home-header04">
        <span>
          User-Driven Wishlists: Users create and manage wishlists, ensuring they
          only see ads for products and services they genuinely want.
        </span>
        <br />
        <br />
        <span>
          Business Offerings: Businesses can list their products or services,
          ensuring a tailored and precise ad campaign targeting the wishlists of
          potential customers.
          <span v-html="rawxgvw"></span>
        </span>
        <br />
        <br />
        <span>
          AI-Enhanced Matching: Highly personalized ads that resonate with
          consumers&apos; genuine interests.
        </span>
        <br />
        <br />
        <span>
          Transparent Feedback: Users can rate and review the ads/products they
          encounter, fostering an environment of continuous improvement.
        </span>
        <br />
        <br />
        <span>
          Decentralized Model: A decentralized governance model, ensuring all
          stakeholders have a say in the platform&apos;s evolution and fostering a
          truly democratic and adaptive ecosystem.
        </span>
      </p>
    </section>
    <section class="home-cards">
      <span id="benefits" class="home-caption03">BENEFITS</span>
      <div class="home-row">
        <div class="home-card">
          <div class="home-avatar">
            <img alt="image" src="/Avatars/avatar.svg" class="home-avatar1" />
          </div>
          <div class="home-main">
            <div class="home-content02">
              <h2 class="home-header05">Personalized Experience</h2>
              <p class="home-description02">
                Receive ads only for products and services you genuinely desire,
                based on your curated wishlist.
              </p>
            </div>
          </div>
        </div>
        <div class="home-card1">
          <div class="home-avatar2">
            <img
              alt="image"
              src="/Avatars/default-avatar.svg"
              class="home-avatar3"
            />
          </div>
          <div class="home-main1">
            <div class="home-content03">
              <h2 class="home-header06">Privacy First</h2>
              <p class="home-description03">
                With anonymous avatars, your personal data remains yours. No
                invasive tracking or data harvesting.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="home-card2">
        <div class="home-avatar4">
          <img alt="image" src="/Avatars/light-avatar.svg" class="home-avatar5" />
        </div>
        <div class="home-row1">
          <div class="home-main2">
            <div class="home-content04">
              <h2 class="home-header07">Targeted Advertising</h2>
              <p class="home-description04">
                Reach out to potential customers who have explicitly shown
                interest in your offerings.
              </p>
            </div>
          </div>
          <img alt="image" src="/group%202262.svg" class="home-image" />
        </div>
      </div>
    </section>

    <section class="home-roadmap">
      <div class="home-heading02">
        <h2 id="roadmap" class="home-header08">Roadmap</h2>
      </div>
      <div class="home-list">
        <div class="home-step">
          <span class="home-caption04">Phase 1 - Q1-Q3, 2024</span>
          <div class="home-heading03">
            <h2 class="home-header09">MVP</h2>
            <p class="home-header10">
              Initiation of the aDeal project by core developers Jacob Lee and
              Brady Liu. Conduct thorough research on blockchain technologies and
              resource availability to support the aDeal network
              development. Prepare comprehensive design documents outlining the
              project architecture, user experience, and technical
              specifications. Engage early with potential users and stakeholders
              to validate the project concept and refine the product design based
              on feedback.
            </p>
          </div>
        </div>
        <div class="home-step1">
          <span class="home-caption05">Phase 2 - Q3, 2024</span>
          <div class="home-heading04">
            <h2 class="home-header11">Pilot Program</h2>
            <!-- <router-link to="/backers-program" class="home-button1 button">Be First in the Know</router-link> -->
            <p class="home-header12">
              <span class="home-text20"
                >Initiate a structured backers program designed to build a core
                group of early supporters who share our vision for revolutionizing
                web3 advertising. </span
              ><br /><br /><span
                >The Backers Program is a cornerstone of the aDeal network&apos;s
                early development strategy, designed to build a robust and
                supportive community foundation while securing necessary funding
                for initial project milestones. It offers a balanced opportunity
                for early investors to participate in a potentially revolutionary
                web3-based advertising network, with a structure that aims to
                align the interests of the developers and the backers for mutual
                long-term benefit.</span
              ><br />
            </p>
          </div>
        </div>
        <div class="home-step2">
          <span class="home-caption06">Phase 3 - Q4, 2024</span>
          <div class="home-heading05">
            <h2 class="home-header13">
              Reputation System Enhancements
              <span v-html="rawt4rm"></span>
            </h2>
            <p class="home-header14">
              Develop a token-based reputation system for aDeal ads, integrating
              it with user feedback and engagement metrics. Implement community
              governance mechanisms, allowing token holders to participate in key
              platform decisions and improvements.
            </p>
          </div>
        </div>
        <div class="home-step3">
          <span class="home-caption07">Phase 4 - Q4 2024</span>
          <div class="home-heading06">
            <h2 class="home-header15">aDeal Network Beta Launch</h2>
            <p class="home-header16">
              Launch the beta version of the aDeal network, incorporating feedback
              from the pilot program and reputation system enhancements. Broaden
              user base, refine platform features, and prepare for full-scale
              public launch
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="home-join-us">
      <div class="home-content05">
        <div class="home-main3">
          <div class="home-heading07">
            <h2 class="home-header17">Create your wishlist now</h2>
            <p class="home-caption08">
              Unlock personalized deals tailored just for you!
            </p>
          </div>
          <button class="home-view2 button" @click="goSignOpenLetter">Sign Open Letter</button>
        </div>
        <img alt="image" src="/group%202273.svg" class="home-image1" />
      </div>
    </section>
    <section class="home-faq">
      <h2 id="faq" class="home-header18">FAQ</h2>
      <div class="home-accordion">
        <div data-role="accordion-container" class="home-element accordion">
          <div class="home-content06">
            <span class="home-header19">Is aDeal free for users?</span>
            <span data-role="accordion-content" class="home-description05">
              Yes, aDeal is free for users. The aDeal network charges businesses
              for the ad service.
            </span>
          </div>
          <div class="home-icon-container">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="home-icon10"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="home-icon12"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
        <div data-role="accordion-container" class="home-element1 accordion">
          <div class="home-content07">
            <span class="home-header20">
              How do I set up my wishlist on aDeal?
            </span>
            <span data-role="accordion-content" class="home-description06">
              Setting up a wishlist is easy! Simply register on aDeal network,
              create an anonymous avatar, and start adding keywords or items
              you&apos;re interested in. Businesses will then be able to send you
              tailored offers based on your preferences.
            </span>
          </div>
          <div class="home-icon-container1">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="home-icon14"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="home-icon16"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
        <div data-role="accordion-container" class="home-element2 accordion">
          <div class="home-content08">
            <span class="home-header21">
              I&apos;m a business. How can I benefit from aDeal?
            </span>
            <span data-role="accordion-content" class="home-description07">
              aDeal provides businesses with direct access to genuine consumer
              needs. By subscribing to user data and pushing ads tailored to
              individual wishlists, businesses can achieve higher engagement
              rates, better ROI, and foster trust with potential customers.
            </span>
          </div>
          <div class="home-icon-container2">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="home-icon18"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="home-icon20"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
        <div data-role="accordion-container" class="home-element3 accordion">
          <div class="home-content09">
            <span class="home-header22">What is ADP token?</span>
            <span data-role="accordion-content" class="home-description08">
              <span>aDeal Utility Point Token (ADP)</span>
              <br />
              <br />
              <span>
                Primary Function: Used for day-to-day transactions within the
                aDeal ecosystem.
              </span>
              <br />
              <br />
              <span>Utility Aspects: </span>
              <span>
                Ad Credits: Businesses use DealTokens to pay for ads or premium
                placements.
              </span>
              <br />
              <br />
              <span>
                User Rewards: Users earn DealTokens for actions like creating
                wishlists, providing feedback, etc. These tokens can be redeemed
                for discounts, services, or at partner businesses..
                <span v-html="rawbjbf"></span>
              </span>
            </span>
          </div>
          <div class="home-icon-container3">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="home-icon22"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="home-icon24"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
        <div data-role="accordion-container" class="home-element4 accordion">
          <div class="home-content10">
            <span class="home-header23">What is ADV token?</span>
            <span data-role="accordion-content" class="home-description09">
              <span>aDeal Governance Token (ADV)</span>
              <br />
              <br />
              <span>
                Primary Function: Represents a stake in the aDeal platform&apos;s
                decision-making process.
              </span>
              <br />
              <span>Governance Aspects:</span>
              <br />
              <br />
              <span>
                Platform Voting: Token holders can propose and vote on platform
                changes, feature developments, and other essential decisions.
              </span>
              <br />
              <br />
              <span>
                Staking for Influence: Stake ADV tokens to increase voting weight
                or influence on the platform.
                <span v-html="raw7onl"></span>
              </span>
            </span>
          </div>
          <div class="home-icon-container4">
            <svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-closed"
              class="home-icon26"
            >
              <path
                d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path></svg
            ><svg
              viewBox="0 0 1024 1024"
              data-role="accordion-icon-open"
              class="home-icon28"
            >
              <path
                d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </section>
    <section class="home-get-yours">
      <div class="home-row2">
        <div class="home-column">
          <div class="home-card3">
            <img
              alt="image"
              src="/Characters/character-9.svg"
              class="home-image2"
            />
          </div>
        </div>
        <div class="home-column1">
          <div class="home-card4">
            <img
              alt="image"
              src="/Characters/character-10.svg"
              class="home-image3"
            />
          </div>
        </div>
      </div>
      <div class="home-column2">
        <div class="home-card5">
          <div class="home-content11">
            <h2 class="home-header24">Join us now</h2>
            <p class="home-description10">
              Join the aDeal Partnership Program and become an integral part of
              our growing community. As a partner, you have the unique opportunity
              to contribute to our vision, drive local engagement, and benefit
              from our expansive network.
            </p>
          </div>
          <a
            href="https://forms.gle/HQvn2deheGsCFteaA"
            target="_blank"
            rel="noreferrer noopener"
            class="home-link07 button"
          >
            Contact Us
          </a>
        </div>
      </div>
    </section>

    <aDealFooter></aDealFooter>

  </div>
</template>

<script>
import aDealHeader from './adeal-header'
import aDealFooter from './adeal-footer'

export default {
  components: {
    aDealHeader,
    aDealFooter
  },
  name: 'Home',
  props: {},
  data() {
    return {
      rawxgvw: ' ',
      rawt4rm: ' ',
      rawbjbf: ' ',
      raw7onl: ' ',
    }
  },
  /*
  metaInfo: {
    title: 'aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'aDeal network',
      },
      {
        property: 'og:description',
        content: 'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
      {
        property: 'og:image',
        content: 'https://adeal.network/hero-divider-1500w.png',
      },
      {
        property: 'og:image:alt',
        content: 'aDeal network',
      },
      {
        property: 'og:image:type',
        content: 'image/png',
      },
      {
        property: 'og:url',
        content: 'https://adeal.network/',
      },
      {
        property: 'og:site_name',
        content: 'aDeal network',
      },
    ],
  },
  */
  methods:{
    goSignOpenLetter() {
      window.open('/sign-open-letter');
    },
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.home-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.home-desktop-menu {
  display: flex;
}
.home-nav {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-link01 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-link02 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-link03 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-link04 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-btn-group {
  gap: var(--dl-space-space-fourunits);
  width: 233px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-view {
  width: 201px;
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text01 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text02 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text03 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text04 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.home-login {
  margin-right: var(--dl-space-space-twounits);
}
.home-icon-group {
  display: flex;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-heading {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}
.home-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  text-align: center;
  line-height: 36px;
}
.home-buttons {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-description {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-divider-image {
  width: 100%;
  object-fit: cover;
}
.home-container3 {
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-end;
  flex-direction: column;
}
.home-description01 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-paragraph {
  color: rgb(255, 255, 255);
  font-size: 40px;
  line-height: 60px;
}
.home-paragraph1 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  line-height: 60px;
}
.home-links {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.home-link05 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  border-top-width: 0px;
}
.home-arrow {
  width: 25px;
  object-fit: cover;
}
.home-link06 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  border-top-width: 0px;
}
.home-arrow1 {
  width: 25px;
  object-fit: cover;
}
.home-collection {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-content01 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption01 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.home-heading01 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header01 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}
.home-header02 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-header03 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-project {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-caption02 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.home-header04 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-cards {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.home-caption03 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.home-row {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-card {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #000000;
}
.home-avatar {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: #FDEA6B;
}
.home-avatar1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-main {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-content02 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header05 {
  color: rgb(255, 255, 255);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.home-description02 {
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}
.home-card1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #FFCF77;
}
.home-avatar2 {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #EC7495;
}
.home-avatar3 {
  width: 28px;
  object-fit: cover;
}
.home-main1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-content03 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header06 {
  color: rgb(0, 0, 0);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.home-description03 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}
.home-card2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #8FA7DF;
}
.home-avatar4 {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}
.home-avatar5 {
  width: 22px;
  object-fit: cover;
}
.home-row1 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-main2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.home-content04 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header07 {
  color: rgb(0, 0, 0);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.home-description04 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}
.home-image {
  width: 100%;
  object-fit: cover;
}
.home-roadmap {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.home-heading02 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: center;
  flex-direction: column;
}
.home-header08 {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  font-weight: 500;
  line-height: 108px;
}
.home-list {
  gap: 106px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  border-left-width: 1px;
}
.home-step {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption04 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.home-heading03 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header09 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}
.home-header10 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-step1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption05 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.home-heading04 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header11 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}
.home-button1 {
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.home-header12 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-step2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption06 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.home-heading05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header13 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}
.home-header14 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-step3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption07 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.home-heading06 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header15 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}
.home-header16 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-join-us {
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-content05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: #FFCF77;
}
.home-main3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading07 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header17 {
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}
.home-caption08 {
  font-size: 24px;
  max-width: 800px;
  line-height: 36px;
}
.home-view2 {
  background-color: rgb(157, 218, 219);
}
.home-image1 {
  width: 100%;
  object-fit: cover;
}
.home-faq {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-header18 {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}
.home-accordion {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
}
.home-element {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
  border-top-width: 1px;
}
.home-content06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header19 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.home-description05 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.home-icon-container {
  padding-top: var(--dl-space-space-halfunit);
}
.home-icon10 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.home-icon12 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.home-element1 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-content07 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header20 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.home-description06 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.home-icon-container1 {
  padding-top: var(--dl-space-space-halfunit);
}
.home-icon14 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.home-icon16 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.home-element2 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-content08 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header21 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.home-description07 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.home-icon-container2 {
  padding-top: var(--dl-space-space-halfunit);
}
.home-icon18 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.home-icon20 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.home-element3 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-content09 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header22 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.home-description08 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.home-icon-container3 {
  padding-top: var(--dl-space-space-halfunit);
}
.home-icon22 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.home-icon24 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.home-element4 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-content10 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header23 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.home-description09 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}
.home-icon-container4 {
  padding-top: var(--dl-space-space-halfunit);
}
.home-icon26 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}
.home-icon28 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}
.home-get-yours {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 555px;
  display: flex;
  max-width: 1440px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.home-row2 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  flex-direction: row;
}
.home-column {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-card3 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
  background-color: #FFCF77;
}
.home-image2 {
  width: 240px;
  object-fit: cover;
}
.home-column1 {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.home-card4 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.home-image3 {
  width: 240px;
  object-fit: cover;
}
.home-column2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-card5 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  flex-direction: column;
  background-color: #8FA7DF;
}
.home-content11 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header24 {
  color: rgb(0, 0, 0);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}
.home-description10 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 18px;
  max-width: 500px;
  line-height: 27px;
}
.home-link07 {
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.home-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-main4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading08 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-logo2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.home-caption09 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-twitter {
  text-decoration: none;
}
.home-image4 {
  width: 100px;
  object-fit: cover;
}
.home-discord {
  text-decoration: none;
}
.home-image5 {
  width: 100px;
  object-fit: cover;
}
.home-links1 {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading09 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.home-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link08 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-list2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.home-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link09 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-link10 {
  color: rgb(255, 255, 255);
}
.home-link11 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-link12 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.home-socials1 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-twitter1 {
  text-decoration: none;
}
.home-image6 {
  width: 100px;
  object-fit: cover;
}
.home-discord1 {
  text-decoration: none;
}
.home-image7 {
  width: 100px;
  object-fit: cover;
}
.home-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
@media(max-width: 991px) {
  .home-desktop-menu {
    display: none;
  }
  .home-btn-group {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-button {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .home-logo1 {
    color: #000000;
  }
  .home-project {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-row {
    flex-direction: column;
  }
  .home-card {
    width: 100%;
  }
  .home-card1 {
    width: 100%;
  }
  .home-row1 {
    flex-direction: column;
  }
  .home-heading07 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-caption08 {
    text-align: center;
  }
  .home-get-yours {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .home-row2 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    height: 555px;
    align-items: center;
    justify-content: center;
  }
  .home-column {
    flex: 1;
    align-items: flex-start;
  }
  .home-card3 {
    width: 100%;
    aspect-ratio: 0.85;
    justify-content: center;
  }
  .home-column1 {
    flex: 1;
    align-items: flex-end;
  }
  .home-card4 {
    width: 100%;
    aspect-ratio: 0.85;
  }
  .home-column2 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-heading {
    gap: var(--dl-space-space-unit);
  }
  .home-header {
    font-size: 36px;
    max-width: 70%;
    line-height: 43px;
  }
  .home-caption {
    font-size: 16px;
    line-height: 24px;
  }
  .home-description {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }
  .home-description01 {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content {
    gap: var(--dl-space-space-unit);
  }
  .home-paragraph {
    font-size: 20px;
    line-height: 30px;
  }
  .home-paragraph1 {
    font-size: 20px;
    line-height: 30px;
  }
  .home-collection {
    gap: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content01 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading01 {
    gap: var(--dl-space-space-unit);
  }
  .home-header01 {
    font-size: 32px;
    letter-spacing: 0;
  }
  .home-header02 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header03 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-project {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-header04 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-cards {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-card {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-main {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header05 {
    font-size: 24px;
  }
  .home-description02 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-card1 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-main1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header06 {
    font-size: 24px;
  }
  .home-description03 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-card2 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-row1 {
    gap: var(--dl-space-space-twounits);
  }
  .home-main2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header07 {
    font-size: 24px;
  }
  .home-description04 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-roadmap {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading02 {
    gap: var(--dl-space-space-unit);
  }
  .home-header08 {
    font-size: 36px;
    line-height: 43px;
  }
  .home-list {
    gap: var(--dl-space-space-threeunits);
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .home-heading03 {
    gap: var(--dl-space-space-unit);
  }
  .home-header09 {
    font-size: 32px;
  }
  .home-header10 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-heading04 {
    gap: var(--dl-space-space-unit);
  }
  .home-header11 {
    font-size: 32px;
  }
  .home-button1 {
    margin-top: 0px;
  }
  .home-header12 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-heading05 {
    gap: var(--dl-space-space-unit);
  }
  .home-header13 {
    font-size: 32px;
  }
  .home-header14 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-heading06 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header15 {
    font-size: 32px;
  }
  .home-header16 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-join-us {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content05 {
    padding-top: var(--dl-space-space-threeunits);
  }
  .home-main3 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading07 {
    gap: var(--dl-space-space-unit);
  }
  .home-header17 {
    font-size: 36px;
    line-height: 43px;
  }
  .home-caption08 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-image1 {
    width: 150%;
  }
  .home-faq {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-header18 {
    font-size: 36px;
    line-height: 43px;
  }
  .home-element {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header19 {
    font-size: 16px;
  }
  .home-description05 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-element1 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header20 {
    font-size: 16px;
  }
  .home-description06 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-element2 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header21 {
    font-size: 16px;
  }
  .home-description07 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-element3 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header22 {
    font-size: 16px;
  }
  .home-description08 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-element4 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header23 {
    font-size: 16px;
  }
  .home-description09 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-get-yours {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-row2 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: 555px;
  }
  .home-card3 {
    padding: var(--dl-space-space-unit);
  }
  .home-image2 {
    width: 100%;
  }
  .home-card4 {
    padding: var(--dl-space-space-unit);
  }
  .home-image3 {
    width: 100%;
  }
  .home-card5 {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content11 {
    align-items: center;
  }
  .home-header24 {
    font-size: 32px;
    text-align: center;
  }
  .home-description10 {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
  }
  .home-link07 {
    margin-top: 0px;
  }
  .home-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-main4 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-heading08 {
    gap: var(--dl-space-space-unit);
  }
  .home-logo2 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-caption09 {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }
  .home-socials {
    display: none;
  }
  .home-links1 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }
  .home-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-list2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-socials1 {
    display: flex;
  }
}
@media(max-width: 479px) {
  .home-navbar {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-image1 {
    width: 200%;
  }
  .home-header19 {
    font-size: 16px;
  }
  .home-description05 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header20 {
    font-size: 16px;
  }
  .home-description06 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header21 {
    font-size: 16px;
  }
  .home-description07 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header22 {
    font-size: 16px;
  }
  .home-description08 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header23 {
    font-size: 16px;
  }
  .home-description09 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-row2 {
    height: 350px;
  }
}
</style>
