<template>
  <div class="sign-open-letter-container">
    <section v-show="!verified" class="sign-open-letter-hero">
      <div class="sign-open-letter-heading">
        <h1 class="sign-open-letter-header">Open Letter</h1>
        <p class="sign-open-letter-caption-center">
          <span style="text-align: center;">To Our Esteemed Community,</span>
        </p>
        <p class="sign-open-letter-caption">
          <span>
            As we embark on this innovative journey with aDeal, a decentralized online advertising network, we invite
            you to join us in transforming the landscape of digital advertising. Our mission is to combat the challenges
            of digital ad fraud, leveraging blockchain technology to ensure transparency and fairness.
          </span>
          <br />
          <br />
          <span>
            By signing this letter with your MetaMask, you are becoming part of a movement that values integrity,
            security, and efficiency in online advertising. Signing this letter will also add you to our whitelist,
            allowing you to participate in the future program of aDeal network.
          </span>
          <br />
          <br />
          <span>
            Together, let's build a network that not only serves the advertising industry but also empowers its
            participants.
          </span>
          <br />
          <br />
          <span>
            To ensure a fair and compliant process, we require all participants of the Backers Program to sign this Open
            Letter and complete the KYC (Know Your Customer) process. We value your privacy and are committed to
            protecting your personal information.
          </span>
          <br />
          <br />
          <span style="font-weight:bold;">
            We respect your privacy. All data will be encrypted and managed under ISO 27701 PIMS procedures. We will not
            share your data with any third-party entities. For KYC, you are required to provide:
          </span>
          <br />Name:
          <br />ID or Passport Number:
          <br />Email:
          <br />Address:
          <br />Country:
          <br />
          <br />
          <span>
            *** Attention Please
            <br />We reserve the right to deny participation in the Backers Program to any individual who does not
            provide accurate and complete information during the KYC process.
          </span>
          <br />
          <br />
          <span>
            *** Participation in the Backers Program is open to eligible parties except for:
            <br />Residents and citizens of jurisdictions on the designated blacklist, which includes countries under
            sanctions by the UN, US, EU, or identified by FATF as high risk.
            U.S. citizens or residents, in compliance with U.S. securities laws.
          </span>
          <br />
          <br />
          <span>
            If you are not interested in the Backers Program, please skip this, just click the finish button. Thank you!
          </span>
          <br />
          <br />
        </p>
        <p class="sign-open-letter-caption-center">
          <span>Sincerely,</span>
          <br />
          <span>aDeal Development Team</span>
        </p>
      </div>

      <div v-show="isMobile()" class="sign-open-letter-hero2">
        <div class="sign-open-letter-view button">Please use your PC browser to open this page and connect with Metamask
        </div>
      </div>

      <div v-show="!isMobile() && !verified" class="sign-open-letter-hero2">
        <div class="sign-open-letter-view button" @click="connect">Step 1. Connect with Metamask
        </div>
        <span v-show="metamask_address.length > 0"
          style='text-align: center;font-size:18px;color:#FFFFFF;margin-top:5px;margin-bottom:5px;'>You're Address: {{
      metamask_address }}</span>

        <div v-show="metamask_address.length > 0" class="sign-open-letter-view button" @click="openSign">Step 2. Please
          click here to sign
        </div>
        <div v-show="sign_message.length > 0" style='width:50%'>
          <form>
            <p style='font-size:18px;color:#FFFFFF;margin-top:5px;margin-bottom:5px;'>Name:</p>
            <input type="text" v-model="kyc_form.name" maxlength='255'
              style='margin-top:5px;margin-bottom:5px;width:100%' placeholder="">

            <p style='font-size:18px;color:#FFFFFF;margin-top:5px;margin-bottom:5px;'>ID or Passport Number:</p>
            <input type="text" v-model="kyc_form.id_passport" maxlength='128'
              style='margin-top:5px;margin-bottom:5px;width:100%' placeholder="">

            <p style='font-size:18px;color:#FFFFFF;margin-top:5px;margin-bottom:5px;'>Email:</p>
            <input type="text" v-model="kyc_form.email" maxlength='255'
              style='margin-top:5px;margin-bottom:5px;width:100%' placeholder="">

            <p style='font-size:18px;color:#FFFFFF;margin-top:5px;margin-bottom:5px;'>Address:</p>
            <input type="text" v-model="kyc_form.living_address" maxlength='1024'
              style='margin-top:5px;margin-bottom:5px;width:100%' placeholder="">

            <p style='font-size:18px;color:#FFFFFF;margin-top:5px;margin-bottom:5px;'>Country:</p>
            <select v-model="kyc_form.living_country" placeholder="" style='margin-top:5px;margin-bottom:5px;width:50%'>
              <option v-for='country in country_list' :value="country.name" :key="country.name"
                style='margin-top:5px;margin-bottom:5px;width:50%'>{{ country.name }}</option>
            </select>
          </form>
        </div>
        <div v-show="sign_message.length > 0" class="sign-open-letter-view button" @click="verifySign">Step 4. Finish
        </div>
      </div>
    </section>

    <section v-show="!isMobile() && verified" class="sign-open-letter-hero">
      <p class="sign-open-letter-caption">
        <span>Congratulations on joining the aDeal community! 🎉</span>
        <br><br>
        <span>To stay informed about our upcoming airdrop and the latest updates, please remember to:</span>
        <br><br>
        <span>1. Follow us on <a href="https://twitter.com/adealnetwork" target="_blank"
            style="color:rgb(29,155,240)">Twitter</a> for real-time news and announcements.</span>
        <br>
        <span>2. Join our <a href="https://discord.gg/rnH2dPFWTf" target="_blank" style="color:rgb(114,137,218)">Discord
            channel</a> to connect with the community and get exclusive insights.
          Thank you for your support, and we look forward to engaging with you!</span>
      </p>
      <a class="sign-open-letter-view button">
        <router-link to="/">Home</router-link>
      </a>
    </section>
  </div>
</template>

<script>
import { DidApi } from '../js/did-api.js';
import Countries from '../js/countries.js';

export default {
  name: 'SignOpenLetter',
  metaInfo: {
    title: 'SignOpenLetter - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'SignOpenLetter - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
  data() {
    return {
      metamask_address: '',
      sign_message: '',
      verified: false,
      country_list: Countries,
      kyc_form: {
        name: '',
        id_passport: '',
        email: '',
        living_address: '',
        living_country: ''
      }
    }
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    connect() {
      if (this.metamask_address.length > 0) return;
      window.connectMetaMask(this.handleAccountsChanged);
      this.scrollToBottom();
    },
    handleAccountsChanged(accounts) {
      console.log('handleAccountsChanged', accounts)
      this.metamask_address = accounts[0];
      this.scrollToBottom();
    },
    async openSign() {
      if (this.sign_message && this.sign_message.length > 0) return;
      this.sign_message = await signForRegister(this.metamask_address);
      if (!this.sign_message) {
        alert("No signature");
        return;
      }
      this.scrollToBottom();
    },
    verifySign() {
      if (this.verified) return;
      let self = this;
      DidApi.did_register(this.metamask_address, "", "", this.kyc_form, this.sign_message, function (err, res) {
        if (err) {
          alert("Register fail");
        }
        else {
          // alert("Register success");
          self.verified = true;
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight);
      })
    }
  },
  mounted() {
    if (typeof window.ethereum !== 'undefined') {
      console.log('MetaMask is installed!')
    }

    //prod
    DidApi.init();

    //dev
    // DidApi.init("http://localhost:1111");
  },
}
</script>

<style scoped>
.sign-open-letter-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}

.sign-open-letter-hero {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}

.sign-open-letter-hero2 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  /* padding-top: var(--dl-space-space-fourunits); */
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}

.sign-open-letter-heading {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sign-open-letter-header {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}

.sign-open-letter-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  text-align: left;
  line-height: 36px;
}

.sign-open-letter-caption-center {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  text-align: center;
  line-height: 36px;
}

.sign-open-letter-buttons {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.sign-open-letter-view {
  text-decoration: none;
}

@media(max-width: 767px) {
  .sign-open-letter-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .sign-open-letter-heading {
    gap: var(--dl-space-space-unit);
  }

  .sign-open-letter-header {
    font-size: 36px;
    max-width: 70%;
    line-height: 43px;
  }

  .sign-open-letter-caption,
  .sign-open-letter-caption-center {
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
