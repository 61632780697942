<template>
  <div class="terms-container">
    <aDealHeader></aDealHeader>

    <section class="terms-collection">
      <div class="terms-content">
        <div class="terms-heading">
          <h2 class="terms-header">User Terms</h2>
          <p class="terms-header1">
            <span>Last Updated: Nov 1, 2023</span>
            <br />
            <br />
            <span>
              Welcome to aDeal! These User Terms of Service (&quot;Terms&quot;)
              govern your access to and use of the aDeal Network and related
              services. Please read them carefully.
            </span>
            <br />
            <br />
            <span>1. Usage and Services</span>
            <br />
            <span>
              Scope: These Terms apply to your use of the aDeal Network and all
              related services.
            </span>
            <br />
            <span>
              Active Development: Our services are continuously evolving. We
              encourage you to review these Terms regularly for any updates or
              changes.
            </span>
            <br />
            <br />
            <span>2. Arbitration Agreement</span>
            <br />
            <span>
              Dispute Resolution: In the event of legal disputes, resolution will
              be through binding arbitration, and you waive the right to a jury
              trial, bench trial, or class action.
            </span>
            <br />
            <br />
            <span>3. Account Management</span>
            <br />
            <span>
              Eligibility: Users under 13 are not permitted to use our services.
              Those under the age of majority must have consent from a guardian.
            </span>
            <br />
            <span>
              Account Responsibility: You are responsible for maintaining the
              confidentiality of your account and for all activities under your
              account.
            </span>
            <br />
            <br />
            <span>4. Acceptable Use</span>
            <br />
            <span>
              Usage Guidelines: You agree not to use the aDeal Network for
              unlawful purposes, to spread harmful or offensive content, or to
              engage in any activity that disrupts or damages our services.
            </span>
            <br />
            <span>
              Content Restrictions: Prohibited activities and content include, but
              are not limited to, harassment, exploitation, spreading malware, and
              infringement of intellectual property.
            </span>
            <br />
            <br />
            <span>5. Intellectual Property and User Content</span>
            <br />
            <span>
              Service Ownership: The intellectual property rights in the services
              are owned by or licensed to aDeal.
            </span>
            <br />
            <span>
              User Content License: You retain rights to the content you create
              but grant aDeal a license to use this content as part of our
              services.
            </span>
            <br />
            <br />
            <span>6. Liability and Indemnification</span>
            <br />
            <span>
              Warranties Disclaimer: The aDeal services are provided &quot;as
              is&quot; without any warranties.
            </span>
            <br />
            <span>
              Liability Limitation: aDeal is not liable for indirect, incidental,
              or consequential damages arising from your use of the services.
            </span>
            <br />
            <span>
              Indemnification: You agree to indemnify aDeal against claims related
              to your use of the services.
            </span>
            <br />
            <br />
            <span>7. General Terms</span>
            <br />
            <span>
              Term and Termination: These Terms remain in effect until terminated
              by either you or aDeal.
            </span>
            <br />
            <span>
              Severability: If any provision is found unenforceable, the remaining
              provisions will continue in effect.
            </span>
            <br />
            <span>
              Waiver: Failure to enforce any part of these Terms does not
              constitute a waiver.
            </span>
            <br />
            <span>
              Export Control: You must comply with all local and international
              export control laws.
            </span>
            <br />
            <br />
            <span>8. Entire Agreement</span>
            <br />
            <span>
              Complete Terms: These Terms constitute the entire agreement between
              you and aDeal regarding your use of the services.
            </span>
            <br />
            <span>
              Independent Relationship: Nothing in these Terms creates any agency,
              partnership, or joint venture between you and aDeal.
            </span>
            <br />
            <br />
            <span>
              For any questions regarding these Terms, please contact us at
              <span v-html="raw7jit"></span>
            </span>
            <a
              href="https://forms.gle/HQvn2deheGsCFteaA"
              target="_blank"
              rel="noreferrer noopener"
              class="terms-link"
            >
              here
            </a>
            <span>.</span>
            <br />
            <br />
            <span>
              By using the aDeal Network, you acknowledge that you have read,
              understood, and agree to be bound by these Terms.
            </span>
            <br />
            <br />
          </p>
        </div>
      </div>
    </section>
    
    <aDealFooter></aDealFooter>
  </div>
</template>

<script>
import aDealHeader from './adeal-header'
import aDealFooter from './adeal-footer'

export default {
  components: {
    aDealHeader,
    aDealFooter
  },
  name: 'Terms',
  data() {
    return {
      raw7jit: ' ',
    }
  },
  metaInfo: {
    title: 'Terms - aDeal network',
    meta: [
      {
        property: 'og:title',
        content: 'Terms - aDeal network',
      },
      {
        property: 'og:description',
        content:
          'Where your wishlist meets perfect offers! Join us in reshaping online advertising with privacy, personalization, and community-driven innovation."',
      },
    ],
  },
}
</script>

<style scoped>
.terms-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}
.terms-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.terms-logo {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.terms-desktop-menu {
  display: flex;
}
.terms-nav {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.terms-button {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.terms-button1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.terms-button2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.terms-button3 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.terms-button4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.terms-btn-group {
  gap: var(--dl-space-space-fourunits);
  width: 233px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.terms-view {
  width: 201px;
}
.terms-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.terms-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.terms-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.terms-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.terms-logo1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.terms-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.terms-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.terms-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-text {
  margin-bottom: var(--dl-space-space-unit);
}
.terms-text01 {
  margin-bottom: var(--dl-space-space-unit);
}
.terms-text02 {
  margin-bottom: var(--dl-space-space-unit);
}
.terms-text03 {
  margin-bottom: var(--dl-space-space-unit);
}
.terms-text04 {
  margin-bottom: var(--dl-space-space-unit);
}
.terms-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.terms-login {
  margin-right: var(--dl-space-space-twounits);
}
.terms-icon-group {
  display: flex;
}
.terms-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.terms-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.terms-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.terms-collection {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.terms-content {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-heading {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  max-width: auto;
  align-items: flex-start;
  flex-direction: column;
}
.terms-header {
  color: rgb(255, 255, 255);
  width: 642px;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}
.terms-header1 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.terms-link {
  text-decoration: underline;
}
.terms-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.terms-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.terms-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.terms-heading1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-logo2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.terms-caption {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.terms-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.terms-twitter {
  text-decoration: none;
}
.terms-image {
  width: 100px;
  object-fit: cover;
}
.terms-discord {
  text-decoration: none;
}
.terms-image1 {
  width: 100px;
  object-fit: cover;
}
.terms-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.terms-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-heading2 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.terms-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-link1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.terms-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-heading3 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.terms-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.terms-link2 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.terms-link3 {
  color: rgb(255, 255, 255);
}
.terms-link4 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.terms-link5 {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.terms-socials1 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.terms-twitter1 {
  text-decoration: none;
}
.terms-image2 {
  width: 100px;
  object-fit: cover;
}
.terms-discord1 {
  text-decoration: none;
}
.terms-image3 {
  width: 100px;
  object-fit: cover;
}
.terms-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
@media(max-width: 991px) {
  .terms-desktop-menu {
    display: none;
  }
  .terms-btn-group {
    display: none;
  }
  .terms-burger-menu {
    display: flex;
  }
  .terms-button5 {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .terms-logo1 {
    color: #000000;
  }
}
@media(max-width: 767px) {
  .terms-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .terms-collection {
    gap: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .terms-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .terms-heading {
    gap: var(--dl-space-space-unit);
  }
  .terms-header {
    font-size: 32px;
    letter-spacing: 0;
  }
  .terms-header1 {
    font-size: 14px;
    line-height: 21px;
  }
  .terms-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .terms-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .terms-heading1 {
    gap: var(--dl-space-space-unit);
  }
  .terms-logo2 {
    font-size: 20px;
    line-height: 24px;
  }
  .terms-caption {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }
  .terms-socials {
    display: none;
  }
  .terms-links {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }
  .terms-list {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .terms-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .terms-socials1 {
    display: flex;
  }
}
@media(max-width: 479px) {
  .terms-navbar {
    padding: var(--dl-space-space-unit);
  }
  .terms-mobile-menu {
    padding: 16px;
  }
}
</style>
