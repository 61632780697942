
import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import router from './router'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'

import {connectMetaMa, getPermission, signForRegister, registerDid, getUserList} from './js/did.js';
// import * as name from './js/did-api.js';

// vue production mode
Vue.config.debug = false;
Vue.config.devtools = false;
Vue.config.silent = true;

// vue dev mode
// Vue.config.debug = true;
// Vue.config.devtools = true;
// Vue.config.silent = false;

import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

Vue.use(LottieVuePlayer)
Vue.config.productionTip = false
// Vue.use(VueGtag, {
//   config: { id: "G-PJQ63SLCRY" }
// });
Vue.use(VueGtag, {
  config: {
      id: 'G-PJQ63SLCRY',
      params: {
          send_page_view: false
      }
  }
}, router)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
