var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-container"},[_c('aDealHeader'),_vm._m(0),_c('section',{staticClass:"roadmap-roadmap"},[_c('div',{staticClass:"roadmap-list"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"roadmap-step02"},[_c('span',{staticClass:"roadmap-caption02"},[_vm._v("Milestone 3 - Q2, 2024")]),_c('div',{staticClass:"roadmap-heading03"},[_c('h2',{staticClass:"roadmap-header06"},[_vm._v("Backers Program")]),_c('button',{staticClass:"roadmap-button6 button",on:{"click":_vm.goBackersProgram}},[_vm._v("Be a Backer")]),_c('p',{staticClass:"roadmap-header07"},[_c('span',[_vm._v("Initiate a structured backers program designed to build a core group of early supporters who share our vision for revolutionizing web3 advertising.")]),_c('br'),_c('br'),_c('span',[_vm._v("The Backers Program is a cornerstone of the aDeal network's early development strategy, designed to build a robust and supportive community foundation while securing necessary funding for initial project milestones. It offers a balanced opportunity for early investors to participate in a potentially revolutionary web3-based advertising network, with a structure that aims to align the interests of the developers and the backers for mutual long-term benefit.")]),_c('br'),_c('br'),_c('span',[_vm._v("For more details of Backers Program, "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.rawwfwe)}})]),_c('router-link',{staticClass:"roadmap-navlink",attrs:{"to":"/backers-program"}},[_vm._v("please reference here")]),_c('span',[_vm._v(".")]),_c('br'),_c('br'),_c('br')],1)])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"roadmap-step09"},[_c('span',{staticClass:"roadmap-caption09"},[_vm._v("Milestone 10 - Q4 2025 "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.rawhckp)}})]),_vm._m(9)])])]),_c('aDealFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"roadmap-collection"},[_c('div',{staticClass:"roadmap-content"},[_c('div',{staticClass:"roadmap-heading"},[_c('h2',{staticClass:"roadmap-header"},[_c('span',[_vm._v("aDeal network Development Roadmap")]),_c('br')]),_c('p',{staticClass:"roadmap-header01"},[_c('span',[_vm._v("Here you'll find all the key milestones of aDeal network development roadmap")]),_c('br'),_c('br')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-step"},[_c('span',{staticClass:"roadmap-caption"},[_vm._v("Milestone 1 - Q4, 2023")]),_c('div',{staticClass:"roadmap-heading01"},[_c('h2',{staticClass:"roadmap-header02"},[_vm._v("Concept Development and Product Design")]),_c('p',{staticClass:"roadmap-header03"},[_c('span',[_vm._v("Initiation of the aDeal project by core developers Jacob Lee and Brady Liu. ")]),_c('span',[_vm._v("Conduct thorough research on blockchain technologies and resource availability to support the aDeal network development. ")]),_c('span',[_vm._v("Prepare comprehensive design documents outlining the project architecture, user experience, and technical specifications. ")]),_c('span',[_vm._v("Engage early with potential users and stakeholders to validate the project concept and refine the product design based on feedback.")]),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-step01"},[_c('span',{staticClass:"roadmap-caption01"},[_vm._v("Milestone 2 - Q2, 2024")]),_c('div',{staticClass:"roadmap-heading02"},[_c('h2',{staticClass:"roadmap-header04"},[_vm._v("Foundation and Community Engagement")]),_c('p',{staticClass:"roadmap-header05"},[_c('span',[_vm._v("Set up the aDeal DAO on Aragon platform with clear governance rules and initial objectives. Begin with discussion forums involve with the crypto community.")]),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-step03"},[_c('span',{staticClass:"roadmap-caption03"},[_vm._v("Milestone 4 - Q3 to Q4, 2024")]),_c('div',{staticClass:"roadmap-heading04"},[_c('h2',{staticClass:"roadmap-header08"},[_vm._v("Development and MVP Launch")]),_c('p',{staticClass:"roadmap-header09"},[_vm._v("Development of the MVP focusing on core functionalities like user avatar management and ad matching algorithms, business AD backend. Launch the MVP for early adopter testing and feedback.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-step04"},[_c('span',{staticClass:"roadmap-caption04"},[_vm._v("Milestone 5 - Q4 2024")]),_c('div',{staticClass:"roadmap-heading05"},[_c('h2',{staticClass:"roadmap-header10"},[_vm._v("Staking Implementation")]),_c('p',{staticClass:"roadmap-header11"},[_c('span',{staticClass:"roadmap-text28"},[_vm._v("Implement staking of ADV to mint ADP (AD Points) for business ad placements with a 30% annual generation rate in first year. Prepare for larger scale expansion based on MVP feedback and initial staking engagement.")]),_c('br'),_c('br'),_c('span',[_vm._v(" The specific generation rate for subsequent years will be determined through a democratic voting process within the aDeal DAO")]),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-step05"},[_c('span',{staticClass:"roadmap-caption05"},[_vm._v("Milestone 6 - Q1 2025")]),_c('div',{staticClass:"roadmap-heading06"},[_c('h2',{staticClass:"roadmap-header12"},[_vm._v("Private Offer B")]),_c('p',{staticClass:"roadmap-header13"},[_vm._v("Private Offer B Conduct Private Offer A with 5% of ADV tokens. Funds raised will be secured in aDeal DAO to support the pilot program in one or two countries and support pre-IDO development stages. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-step06"},[_c('span',{staticClass:"roadmap-caption06"},[_vm._v("Milestone 7 - Q1 2025")]),_c('div',{staticClass:"roadmap-heading07"},[_c('h2',{staticClass:"roadmap-header14"},[_vm._v("Official Product Launch and Market Maker Engagement")]),_c('p',{staticClass:"roadmap-header15"},[_vm._v("Launch the aDeal network officially with complete functionalities. Engage market makers to prepare for IDO and support liquidity on DEXs like Uniswap.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-step07"},[_c('span',{staticClass:"roadmap-caption07"},[_vm._v("Milestone 8 - Q2 2025")]),_c('div',{staticClass:"roadmap-heading08"},[_c('h2',{staticClass:"roadmap-header16"},[_vm._v("Regulation & Compliance")]),_c('p',{staticClass:"roadmap-header17"},[_c('span',[_vm._v("Register the aDeal network landing company in Singapore, to ensure compliance with local and international regulations. Implement comprehensive compliance measures to meet the regulatory requirements for running a web3-based advertising platform.")]),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-step08"},[_c('span',{staticClass:"roadmap-caption08"},[_vm._v("Milestone 9 - Q3 2025")]),_c('div',{staticClass:"roadmap-heading09"},[_c('h2',{staticClass:"roadmap-header18"},[_vm._v("IDO Launchpad")]),_c('p',{staticClass:"roadmap-header19"},[_vm._v("Conduct the IDO on IDO Launchpad, provide market makers programs, increase the token's liquidity, and engage a broader community base. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap-heading10"},[_c('h2',{staticClass:"roadmap-header20"},[_vm._v("Post-Launch Growth and Sustainability")]),_c('p',{staticClass:"roadmap-header21"},[_vm._v("Continue support and expansion efforts. Ongoing development based on user feedback and market needs. Strengthen and expand the ecosystem with new features and partnerships.")])])
}]

export { render, staticRenderFns }